export default {
  layout: state => state.layout,
  ultimaTela: state => state.ultimaTela,
  alerta: state => state.alerta,

  agendamentoQuery: state => state.agendamentoQuery,
  agendamentoLastItemId: state => state.agendamentoLastItemId,
  agendamentoRefreshList: state => state.agendamentoRefreshList,

  atendimentoQuery: state => state.atendimentoQuery,
  atendimentoLastItemId: state => state.atendimentoLastItemId,
  atendimentoRefreshList: state => state.atendimentoRefreshList,
  relatorioAtendimentoExportQuery: state => state.relatorioAtendimentoExportQuery,

  exameQuery: state => state.exameQuery,
  exameLastItemId: state => state.exameLastItemId,
  exameRefreshList: state => state.exameRefreshList,
  exameFilters: state => state.exameFilters,

  reportsQuery: state => state.reportsQuery,
  reportsFilters: state => state.reportsFilters,

  updateFavoriteModel: state => state.updateFavoriteModel,
  notifications: state => state.notifications,

  autoTextoQuery: state => state.autoTextoQuery,

  // safelyStoredNumber: state => state.safelyStoredNumber,
  //  storedNumberMatches(state) {
  //    return matchNumber => {
  //      return state.safelyStoredNumber === matchNumber;
  //    }
  //  }
  // Shorthand:
  // storedNumberMatches: state => matchNumber => state.safelyStoredNumbers === matchNumber
}
