import { dateFormatStartDay, dateFormatEndDay, dateFormat } from './dateUtils'

function getExameFilters(value) {
  const exameFilters = JSON.parse(JSON.stringify(value));
  let textFilter = "";
  let urlFilter = "";

  if (exameFilters.dataInicial && exameFilters.dataInicial.length > 0) {
    textFilter += `dataInicial=${exameFilters.dataInicial} `;
    urlFilter += `&dataInicial=${dateFormatStartDay(exameFilters.dataInicial, "DD/MM/YYYY")}`;
  }

  if (exameFilters.dataFinal && exameFilters.dataFinal.length > 0) {
    textFilter += `dataFinal=${exameFilters.dataFinal} `;
    urlFilter += `&dataFinal=${dateFormatEndDay(exameFilters.dataFinal, "DD/MM/YYYY")}`;
  }
  
  if (exameFilters.turnoHoraInicial && exameFilters.turnoHoraInicial.length > 0) {
    textFilter += `turnoHoraInicial=${exameFilters.turnoHoraInicial} `;
    urlFilter += `&turnoHoraInicial=${dateFormat(exameFilters.turnoHoraInicial, "HH:mm")}`;
  }

  if (exameFilters.turnoHoraFinal && exameFilters.turnoHoraFinal.length > 0) {
    textFilter += `turnoHoraFinal=${exameFilters.turnoHoraFinal} `;
    urlFilter += `&turnoHoraFinal=${dateFormat(exameFilters.turnoHoraFinal, "HH:mm")}`;
  }

  if (exameFilters.pacienteNome && exameFilters.pacienteNome.length > 0) {
    if (isNaN(exameFilters.pacienteNome)) {
      textFilter += `pacienteNome=${exameFilters.pacienteNome} `;
      urlFilter += `&pacienteNome=${exameFilters.pacienteNome}`;
    } else {
      textFilter += `patientId=${exameFilters.pacienteNome} `;
      urlFilter += `&patientId=${exameFilters.pacienteNome}`;
    }
  }

  if (exameFilters.pacienteQuery && exameFilters.pacienteQuery.length > 0) {
    textFilter += `pacienteQuery=${exameFilters.pacienteQuery} `;
    urlFilter += `&pacienteQuery=${exameFilters.pacienteQuery}`;
  }
  
  if (exameFilters.grupoEmpresa && exameFilters.grupoEmpresa.length > 0) {
    textFilter += `grupoEmpresaIds=${exameFilters.grupoEmpresa
      .map((k) => k.nome)
      .join(",")} `;
    urlFilter += `&grupoEmpresaIds=${exameFilters.grupoEmpresa.map(
      (k) => k.id
    )}`;
  }

  if (exameFilters.empresa && exameFilters.empresa.length > 0) {
    textFilter += `empresaIds=${exameFilters.empresa
      .map((k) => k.nome)
      .join(",")} `;
    urlFilter += `&empresaIds=${exameFilters.empresa.map((k) => k.id)}`;
  }

  if (exameFilters.unidade && exameFilters.unidade.length > 0) {
    textFilter += `unidadeIds=${exameFilters.unidade
      .map((k) => k.nome)
      .join(",")} `;
    urlFilter += `&unidadeIds=${exameFilters.unidade.map((k) => k.id)}`;
  }

  if (exameFilters.setor && exameFilters.setor.length > 0) {
    textFilter += `setorIds=${exameFilters.setor
      .map((k) => k.nome)
      .join(",")} `;
    urlFilter += `&setorIds=${exameFilters.setor.map((k) => k.id)}`;
  }

  if (exameFilters.modalidade && exameFilters.modalidade.length > 0) {
    textFilter += `modalidadeIds=${exameFilters.modalidade
      .map((k) => k.nome)
      .join(",")} `;
    urlFilter += `&modalidadeIds=${exameFilters.modalidade.map((k) => k.id)}`;
  }

  if (exameFilters.grupoEspecialidade && exameFilters.grupoEspecialidade.length > 0) {
    textFilter += `grupoEspecialidadeIds=${exameFilters.grupoEspecialidade
      .map((k) => k.nome)
      .join(",")} `;
    urlFilter += `&grupoEspecialidadeIds=${exameFilters.grupoEspecialidade.map(
      (k) => k.id
    )}`;
  }

  if (exameFilters.especialidade && exameFilters.especialidade.length > 0) {
    textFilter += `especialidadeIds=${exameFilters.especialidade
      .map((k) => k.nome)
      .join(",")} `;
    urlFilter += `&especialidadeIds=${exameFilters.especialidade.map(
      (k) => k.id
    )}`;
  }

  if (exameFilters.tipoExame && exameFilters.tipoExame.length > 0) {
    textFilter += `tipoExameIds=${exameFilters.tipoExame
      .map((k) => k.nome)
      .join(",")} `;
    urlFilter += `&tipoExameIds=${exameFilters.tipoExame.map((k) => k.id)}`;
  }

  if (
    exameFilters.medicoRadiologista &&
    exameFilters.medicoRadiologista.length > 0
  ) {
    textFilter += `medicoRadiologista=${exameFilters.medicoRadiologista
      .map((k) => k.nomeExibicao)
      .join(",")} `;
    urlFilter += `&medicoRadiologista=${exameFilters.medicoRadiologista.map(
      (k) => k.id
    )}`;
  }

  if (exameFilters.statusExame && exameFilters.statusExame.length > 0) {
    textFilter += `statusExameIds=${exameFilters.statusExame
      .map((k) => k.descricao)
      .join(",")} `;
    urlFilter += `&statusExameIds=${exameFilters.statusExame.map(
      (k) => k.id
    )}`;
  }
  
  if (exameFilters.solicitacaoPrioridade && exameFilters.solicitacaoPrioridade.length > 0) {
    textFilter += `solicitacaoPrioridade=${exameFilters.solicitacaoPrioridade
      .map((k) => k.descricao)
      .join(",")} `;
    urlFilter += `&solicitacaoPrioridade=${exameFilters.solicitacaoPrioridade.map(
      (k) => k.id
    )}`;
  }

  if (
    exameFilters.medicoLaudo &&
    exameFilters.medicoLaudo.length > 0
  ) {
    textFilter += `medicoLaudoIds=${exameFilters.medicoLaudo
      .map((k) => k.nomeExibicao)
      .join(",")} `;
    urlFilter += `&medicoLaudoIds=${exameFilters.medicoLaudo.map(
      (k) => k.id
    )}`;
  }

  if (
    exameFilters.medicoDirecionamento &&
    exameFilters.medicoDirecionamento.length > 0
  ) {
    textFilter += `medicoDirecionamentoIds=${exameFilters.medicoDirecionamento
      .map((k) => k.nomeExibicao)
      .join(",")} `;
    urlFilter += `&medicoDirecionamentoIds=${exameFilters.medicoDirecionamento.map(
      (k) => k.id
    )}`;
  }

  if (
    exameFilters.medicoSolicitante &&
    exameFilters.medicoSolicitante.length > 0
  ) {
    textFilter += `medicoSolicitanteIds=${exameFilters.medicoSolicitante
      .map((k) => k.nomeExibicao)
      .join(",")} `;
    urlFilter += `&medicoSolicitanteIds=${exameFilters.medicoSolicitante.map(
      (k) => k.id
    )}`;
  }
  
  if (exameFilters.imagens) {
    textFilter += `imagens=${exameFilters.imagens}`;
    urlFilter += `&imagens=${exameFilters.imagens}`;
  }

  if (exameFilters.direcionados) {
    textFilter += `direcionados=${exameFilters.direcionados}`;
    urlFilter += `&direcionados=${exameFilters.direcionados}`;
  }

  if (exameFilters.reconvocados) {
    textFilter += `reconvocados=${exameFilters.reconvocados}`;
    urlFilter += `&reconvocados=${exameFilters.reconvocados}`;
  }

  if (exameFilters.pendentes) {
    textFilter += `pendentes=${exameFilters.pendentes}`;
    urlFilter += `&pendentes=${exameFilters.pendentes}`;
  }

  if (exameFilters.revisoes) {
    textFilter += `revisoes=${exameFilters.revisoes}`;
    urlFilter += `&revisoes=${exameFilters.revisoes}`;
  }

  return { ...exameFilters, textFilter, urlFilter };
}

function isObjectFilter(property, value) {
  if (!['textFilter','urlFilter','periodo','pacienteQuery'].includes(property) && value != null && value.length > 0) {
    return true
  } else if ((property == 'imagens' || property == 'direcionados' || property == 'reconvocados' || property == 'pendentes' || property == 'revisoes') && value){
    return true
  }
  return false
}

export {
  getExameFilters,
  isObjectFilter,
}