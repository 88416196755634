export default {
  setLayout(state, params) {
    state.layout = params.query
  },
  setUltimaTela(state, params) {
    state.ultimaTela = params.tela
  },
  setAlerta(state, params) {
    state.alerta = params
  },

  setAgendamentoQuery(state, params) {
    state.agendamentoQuery = params.query
  },
  setAgendamentoRefreshList(state, params) {
    state.agendamentoRefreshList = params
  },
  setAgendamentoLastItemId(state, params) {
    state.agendamentoLastItemId = params
  },

  setAtendimentoQuery(state, params) {
    state.atendimentoQuery = params.query
  },
  setAtendimentoRefreshList(state, params) {
    state.atendimentoRefreshList = params
  },
  setAtendimentoLastItemId(state, params) {
    state.atendimentoLastItemId = params
  },
  setRelatorioAtendimentoExportQuery(state, params) {
    state.relatorioAtendimentoExportQuery = params.query
  },

  setExameQuery(state, params) {
    state.exameQuery = params.query
  },
  setExameRefreshList(state, params) {
    state.exameRefreshList = params
  },
  setExameLastItemId(state, params) {
    state.exameLastItemId = params
  },
  setExameFilters(state, params) {
    state.exameFilters = params
  },
  setShowExameFilters(state, params) {
    state.exameFilters.showFilters = params
  },

  setReportsQuery(state, params) {
    state.reportsQuery = params.query
  },
  setReportsFilters(state, params) {
    state.reportsFilters = params
  },
  setShowReportsFilters(state, params) {
    state.reportsFilters.showFilters = params
  },

  setUpdateFavoriteModel(state, params) {
    state.updateFavoriteModel = params
  },

  setNotifications(state, params) {
    state.notifications = params
  },

  async addNotifications(state, callback) {
    state.notifications.push({ dateRequest: new Date(), ...await callback })
  },

  removeNotifications(state, params) {
    state.notifications.splice(state.notifications.indexOf(params), 1)
  },

  setAutoTextoQuery(state, params) {
    state.autoTextoQuery = params.query
  },
}

