<template>
  <v-container fluid class="pa-0 ma-0">
    <v-autocomplete
      rounded
      filled
      dense
      :items="items"
      :label="label"
      item-value="id"
      :hide-details="true"
      :item-text="itemText"
      v-model="selectedItems"
      chips
      multiple
      return-object
      deletable-chips
      @update:search-input="customSearchInput"
    >
      <template v-slot:selection="{ item }" v-if="tipo != 'solicitacaoPrioridade'">
        <v-chip close small @click:close="removeItem(item)">
          <span>{{
            itemText === "descricao"
              ? item.descricao
              : itemText === "nomeExibicao"
              ? item.nomeExibicao
              : item.nome || item.descricao
          }}</span>
        </v-chip>
      </template>
      <!-- Tratamento para exibição de filtro por solicitacaoPrioridade personalizado -->
      <template v-slot:selection="data" v-else>
        <v-chip
          small
          label
          close
          text-color="white"
          :color="data.item.color"
          @click:close="removeItem(data.item)"
        >
          {{ data.item.descricao }}
        </v-chip>
      </template>
      <template v-slot:item="data" v-if="tipo == 'solicitacaoPrioridade'">
        <v-chip
          small
          label
          text-color="white"
          :color="data.item.color"
        >
          {{ data.item.descricao }}
        </v-chip>
      </template>
      <!-- Tratamento para exibição de filtro por solicitacaoPrioridade personalizado -->
    </v-autocomplete>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "autocomplete-filter",

  props: {
    tipo: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      items: [],
      selectedItems: this.value,
      label: "",
      itemText: "nome",
      medicoSolicitanteTimeout: null,
    };
  },

  watch: {
    value(value) {
      this.selectedItems = value;
    },
    selectedItems(value) {
      this.$emit("input", value);
    },
  },

  computed: {
    ...mapGetters(["exameFilters"]),
  },

  methods: {
    ...mapActions(["setAlerta", "setExameFilters"]),
    customSearchInput(value) {
      if (value && this.tipo == "medicoSolicitante") {
        const secondsTimeout = 500
        const timeoutFuncition = () => {
          this.buscarMedicosSolicitante(value)
        }

        if (!this.medicoSolicitanteTimeout) {
          this.medicoSolicitanteTimeout = setTimeout(timeoutFuncition, secondsTimeout)
        } else {
          clearTimeout(this.medicoSolicitanteTimeout)
          this.medicoSolicitanteTimeout = setTimeout(timeoutFuncition, secondsTimeout)
        }
      } else if (this.tipo == "medicoSolicitante") {
        clearTimeout(this.medicoSolicitanteTimeout)
        this.items = []
      }
      return
    },
    removeItem(item) {
      let index = this.selectedItems.indexOf(item);
      if (index != -1) {
        this.selectedItems.splice(index, 1);
      } else if (item.id) {
        let itemToRemove = this.selectedItems.filter((i) => i.id === item.id);
        if (itemToRemove.length > 0) {
          index = this.selectedItems.indexOf(itemToRemove[0]);
          this.selectedItems.splice(index, 1);
        }
      }
    },
    selecionaTipoComponente() {
      if (this.tipo === "unidade") {
        this.label = "Unidade";
        this.buscarUnidades();
      } else if (this.tipo === "grupoEmpresa") {
        this.label = "Grupo Empresa";
        this.buscarGrupoEmpresas();
      } else if (this.tipo === "empresa") {
        this.label = "Empresa";
        this.buscarEmpresas();
      } else if (this.tipo === "modalidade") {
        this.label = "Modalidade";
        this.itemText = (value) => {
          return value?.sigla
            ? `${value.sigla} - ${value.nome}`
            : `${value.nome}`;
        }
        this.buscarModalidades();
      } else if (this.tipo === "setor") {
        this.label = "Setor";
        this.itemText = (value) => {
          return value.unidade?.nome
            ? `${value.nome} (${value.unidade.nome})`
            : `${value.nome}`;
        }
        this.buscarSetores();
      } else if (this.tipo === "grupoEspecialidade") {
        this.label = "Especialidade";
        this.buscarGrupoEspecialidades();
      } else if (this.tipo === "especialidade") {
        this.label = "Subespecialidade";
        this.itemText = (value) => {
          return value.grupoEspecialidade?.nome
            ? `${value.descricao} (${value.grupoEspecialidade?.nome})`
            : `${value.descricao}`;
        }
        this.buscarEspecialidades();
      } else if (this.tipo === "tipoExame") {
        this.label = "Tipo Exame";
        this.itemText = (value) => {
          return value?.modalidade?.sigla
            ? `${value.modalidade.sigla} - ${value.nome} (${value.descricao})`
            : `${value.nome}`
        }
    
        this.buscarTipoExames();
      } else if (this.tipo === "medicoRadiologista") {
        this.label = "Médico Radiologista";
        this.itemText = "nomeExibicao";
        this.buscarMedicoRadiologista();
      } else if (this.tipo === "statusExame") {
        this.label = "Status Exame";
        this.itemText = "descricao";
        this.buscarStatusExame();
      } else if (this.tipo === "solicitacaoPrioridade") {
        this.label = "Prioridade";
        this.itemText = "descricao";
        this.buscarSolicitacaoPrioridade();
      } else if (this.tipo === "tipoConsulta") {
        this.label = "Tipo Consulta";
        this.itemText = "descricao";
        this.buscarTipoConsultas();
      } else if (this.tipo === "medicoLaudo") {
        this.label = "Médico";
        this.itemText = "nomeExibicao";
        this.buscarMedicos();
      } else if (this.tipo === "medicoDirecionamento") {
        this.label = "Médico Direcionamento";
        this.itemText = "nomeExibicao";
        this.buscarMedicos();
      } else if (this.tipo === "medicoSolicitante") {
        this.label = "Pesquisar Médico Solicitante";
        this.itemText = (value) => {
          const char = '|'
          let text = `${value.nome}`
          text += value?.tipoConselho ? ` ${char} ${value.tipoConselho}` : ''
          text += value?.registroConselho ? ` ${char} ${value.registroConselho}` : ''
          return text
        };
      }
    },
    buscarUnidades() {
      const self = this;
      self.$http
        .get(`/unidade?max=-1`)
        .then((response) => {
          this.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar unidades",
          });
        });
    },
    buscarGrupoEmpresas() {
      const self = this;
      self.$http
        .get(`/grupoEmpresa?max=-1&situacao=true`)
        .then((response) => {
          self.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Grupos de Empresas",
          });
        });
    },
    buscarEmpresas() {
      const self = this;
      self.$http
        .get(`/empresa?max=-1`)
        .then((response) => {
          self.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Empresas",
          });
        });
    },
    buscarModalidades() {
      const self = this;
      self.$http
        .get(`/modalidade?max=-1`)
        .then((response) => {
          self.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Empresas",
          });
        });
    },
    buscarSetores() {
      const self = this;
      self.$http
        .get(`/setor?max=-1`)
        .then((response) => {
          self.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Setores",
          });
        });
    },
    buscarGrupoEspecialidades() {
      const self = this;
      self.$http
        .get(`/grupoEspecialidade?max=-1`)
        .then((response) => {
          self.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Especialidades",
          });
        });
    },
    buscarEspecialidades() {
      const self = this;
      self.$http
        .get(`/especialidade?max=-1`)
        .then((response) => {
          self.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Subspecialidades",
          });
        });
    },
    buscarTipoExames() {
      const self = this;
      self.$http
        .get(`/tipoExame?max=-1`)
        .then((response) => {
          self.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Tipo Exames",
          });
        });
    },
    buscarMedicoRadiologista() {
      const self = this;
      self.$http
        .get(`/perfil?authority=ROLE_MEDICO_RADIOLOGISTA&max=-1`)
        .then((response) => {
          self.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Médicos Radiologistas",
          });
        });
    },
    buscarStatusExame() {
      const self = this;
      self.$http
        .get(`/statusExame?max=-1`)
        .then((response) => {
          self.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Médicos Radiologistas",
          });
        });
    },
    buscarSolicitacaoPrioridade() {
      this.items = [
        { id: "1", descricao: "BAIXA ", color: "blue darken-1" },
        { id: "2", descricao: "MÉDIA ", color: "green darken-1" },
        { id: "3", descricao: "ALTA  ", color: "yellow darken-2" },
        { id: "4", descricao: "MÁXIMA", color: "red darken-1" },
      ]
    },
    buscarTipoConsultas() {
      const self = this;
      self.$http
        .get(`/tipoConsulta?max=-1`)
        .then((response) => {
          self.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Tipos Consulta",
          });
        });
    },
    buscarMedicos() {
      const self = this;
      const roles = ["ROLE_MEDICO_RADIOLOGISTA", "ROLE_MEDICO_ATENDIMENTO"]
      self.$http
        .get(`/perfil?authority=${roles}&max=-1&sort=nomeExibicao`)
        .then((response) => {
          self.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Médicos",
          });
        });
    },
    buscarMedicosSolicitante(p_medicoSolicitanteNome) {
      const self = this;
      let params = p_medicoSolicitanteNome ? `&nome=${p_medicoSolicitanteNome}` :  ''
      self.$http
        .get(`/medicoSolicitante?max=50${params}`)
        .then((response) => {
          self.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Médicos Solicitantes",
          });
        });
    },
  },

  created() {
    this.selecionaTipoComponente();
  },
};
</script>