var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","allow-overflow":"","nudge-top":"-8","min-width":"200","max-height":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"overlap":"","color":_vm.itemsCount > 0 ? 'red' : 'transparent',"content":_vm.itemsCount}},[_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.itemsCount == 0,"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-bell-outline")])],1),_c('v-snackbar',{staticClass:"mt-9 mr-10 teste",attrs:{"color":"success","right":"","top":"","timeout":6000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('v-btn',{attrs:{"small":"","text":""},nativeOn:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Fechar")])],1)],1)],1)]}}])},[(_vm.itemsCount > 0)?_c('v-list',{staticClass:"pt-0"},[_c('v-list-item',{staticClass:"primary",attrs:{"dark":""}},[_c('v-list-item-title',[_c('span',{staticClass:"headline"},[_vm._v("Notificações")])]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"open-delay":"800","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.removeAll}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-sweep-outline")])],1)]}}],null,false,3963645194)},[_c('span',[_vm._v("Apagar todos")])])],1)],1),_c('v-divider'),_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.onClick(item)}}},[_c('v-list-item-avatar',[_c('v-avatar',{staticClass:"white--text",attrs:{"color":"primary","size":"56"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(_vm.getIcon(item.type)))])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.message)+" - "+_vm._s(item.data.target || item.data.download))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.dateFormat(item.dateCreated)))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"open-delay":"800","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onRemove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Apagar Notificação")])])],1)],1)})],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }