<template>
  <v-container fluid class="pa-0">
    <div class="text-center">
      <v-menu
        v-model="menu"
        :open-on-click="false"
        :close-on-content-click="false"
        offset-y
        nudge-top="-5"
        nudge-left="60"
        min-width="200"
        max-height="97%"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            v-on="on"
            solo
            flat
            dense
            filled
            :hide-details="true"
            v-model.trim="modelFilters.pacienteQuery"
            @keypress="setTextSearch"
            @click:append="menu = true"
            append-icon="mdi-filter-variant"
            :label="'ID do Exame ou Paciente: Nome, CPF'"
          >
            <template v-slot:prepend-inner>
              <div
                v-for="(value, property, index) in modelFilters"
                :key="index"
              >
                <v-chip-group v-if="isObjectFilter(property, value)">
                  <v-chip
                    v-if="!Array.isArray(value)"
                    class="ml-1"
                    close
                    small
                    @click:close="closeChip(property)"
                  >
                    {{
                      property === "dataInicial"
                        ? "De: " + value
                        : property === "dataFinal"
                        ? "Até: " + value
                        : property === "turnoHoraInicial"
                        ? "De: " + value
                        : property === "turnoHoraFinal"
                        ? "Até: " + value
                        : property === 'imagens' ? 'Somente Imagens'
                        : property === 'direcionados' ? 'Somente Direcionados'
                        : property === 'reconvocados' ? 'Somente Reconvocados'
                        : property === 'pendentes' ? 'Somente Pendentes'
                        : property === 'revisoes' ? 'Somente Revisões'
                        : value
                    }}
                  </v-chip>
                  <v-chip
                    v-else
                    v-for="(item, i) of value"
                    :key="i"
                    class="ml-1"
                    close
                    small
                    @click:close="closeArrayChip(value, i)"
                  >
                    {{
                      property === "modalidade"
                        ? item.sigla
                        : item.nome
                        ? item.nome
                        : item.descricao
                        ? item.descricao
                        : item.nomeExibicao
                    }}
                  </v-chip>
                </v-chip-group>
              </div>
            </template>
          </v-text-field>
        </template>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col md="12" cols="12">
                <v-autocomplete
                  v-model="modelFilters.tipoRelatorio"
                  :items="itemsTipoRelatorio"
                  label="Selecionar Tipos de Relatórios"
                  item-text="nome"
                  item-value="id"
                  rounded
                  filled
                  dense
                  hide-details="auto"
                  hide-selected
                  return-object
                  @change="changeTipoRelatorio"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="12">
                <periodo-filter
                  v-model="modelFilters"
                  :labelPeriodo="'Período'"
                  :labelDataInicial="'Data incial'"
                  :labelDataFinal="'Data final'"
                ></periodo-filter>
              </v-col>
              <v-col cols="12" md="12">
                <turno-filter v-model="modelFilters"></turno-filter>
              </v-col>
              <v-col md="4" cols="12">
                <text-field-filter
                  v-model="modelFilters.pacienteQuery"
                  tipo="pacienteQuery"
                  :label="'ID do Exame ou Paciente: Nome, CPF'"
                ></text-field-filter>
              </v-col>
              <v-col md="4" cols="12">
                <text-field-filter
                  v-model="modelFilters.pacienteNome"
                  tipo="pacienteNome"
                  :label="'Paciente: Nome ou PatientId'"
                ></text-field-filter>
              </v-col>
              <v-col md="4" cols="12">
                <autocomplete-filter
                  v-model="modelFilters.tipoExame"
                  tipo="tipoExame"
                ></autocomplete-filter>
              </v-col>
              <v-col md="4" cols="12">
                <autocomplete-filter
                  v-model="modelFilters.statusExame"
                  tipo="statusExame"
                ></autocomplete-filter>
              </v-col>
              <v-col md="4" cols="12">
                <autocomplete-filter
                  v-model="modelFilters.grupoEmpresa"
                  tipo="grupoEmpresa"
                ></autocomplete-filter>
              </v-col>
              <v-col md="4" cols="12">
                <autocomplete-filter
                  v-model="modelFilters.empresa"
                  tipo="empresa"
                ></autocomplete-filter>
              </v-col>
              <v-col md="4" cols="12">
                <autocomplete-filter
                  v-model="modelFilters.unidade"
                  tipo="unidade"
                ></autocomplete-filter>
              </v-col>
              <v-col md="4" cols="12">
                <autocomplete-filter
                  v-model="modelFilters.setor"
                  tipo="setor"
                ></autocomplete-filter>
              </v-col>
              <v-col md="4" cols="12">
                <autocomplete-filter
                  v-model="modelFilters.medicoLaudo"
                  tipo="medicoLaudo"
                ></autocomplete-filter>
              </v-col>
              <v-col md="4" cols="12">
                <autocomplete-filter
                  v-model="modelFilters.medicoSolicitante"
                  tipo="medicoSolicitante"
                ></autocomplete-filter>
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter
                  v-model="modelFilters.medicoDirecionamento"
                  tipo="medicoDirecionamento"
                />
              </v-col>
              <v-col md="4" cols="12">
                <autocomplete-filter
                  v-model="modelFilters.modalidade"
                  tipo="modalidade"
                ></autocomplete-filter>
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter
                  v-model="modelFilters.grupoEspecialidade"
                  tipo="grupoEspecialidade"
                />
              </v-col>
              <v-col md="4" cols="12">
                <autocomplete-filter
                  v-model="modelFilters.especialidade"
                  tipo="especialidade"
                ></autocomplete-filter>
              </v-col>
              <v-col md="4" cols="12">
                <autocomplete-filter
                  v-model="modelFilters.tipoConsulta"
                  tipo="tipoConsulta"
                ></autocomplete-filter>
              </v-col>
              <v-col md="4" cols="12">
                <autocomplete-filter
                  v-model="modelFilters.solicitacaoPrioridade"
                  tipo="solicitacaoPrioridade"
                ></autocomplete-filter>
              </v-col>
              <v-col md="4" cols="12">
                <v-switch
                  v-model="modelFilters.imagens"
                  :label="`Somente com imagens`"
                ></v-switch>
              </v-col>
              <v-col md="4" cols="12">
                <v-switch
                  v-model="modelFilters.direcionados"
                  :label="`Somente direcionados`"
                ></v-switch>
              </v-col>
              <v-col md="4" cols="12">
                <v-switch
                  v-model="modelFilters.reconvocados"
                  :label="`Somente reconvocados`"
                ></v-switch>
              </v-col>
              <v-col md="4" cols="12">
                <v-switch
                  v-model="modelFilters.pendentes"
                  :label="`Somente pendentes`"
                ></v-switch>
              </v-col>
              <v-col md="4" cols="12">
                <v-switch
                  v-model="modelFilters.revisoes"
                  :label="`Somente Revisões`"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="pr-4">
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="limpar"> Limpar </v-btn>
            <v-btn color="primary" @click="applyFilters"> Aplicar </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import AutocompleteFilter from "../exame/AutocompleteFilter.vue";
import TextFieldFilter from "../exame/TextFieldFilter.vue";
import PeriodoFilter from "../exame/PeriodoFilter.vue";
import TurnoFilter from "../exame/TurnoFilter.vue";
import { getExameFilters, isObjectFilter } from "@/utils/exameFilter";

export default {
  name: "reports-filter",
  data() {
    return {
      menu: false,
      tipoRelatorio: null,
      modelFilters: {
        periodo: null,
        dataInicial: null,
        dataFinal: null,

        turnoPeriodo: null,
        turnoHoraInicial: null,
        turnoHoraFinal: null,
        imagens: false,
        direcionados: false,
        reconvocados: false,
        pendentes: false,
        revisoes: false,
      },
      itemsTipoRelatorio: [
        {
          id: 1,
          nome: "Relatórios de Agendamentos",
          tipo: "AGENDAMENTO",
        },
        {
          id: 2,
          nome: "Relatório de Cancelamentos",
          tipo: "CANCELAMENTO",
        },
        {
          id: 3,
          nome: "Relatório de Atendimento",
          tipo: "ATENDIMENTO",
        },
      ],
      titulo: "Relatório de Atendimento",
    };
  },
  components: {
    AutocompleteFilter,
    PeriodoFilter,
    TextFieldFilter,
    TurnoFilter,
  },
  computed: {
    ...mapGetters(["reportsQuery", "reportsFilters"]),
  },
  watch: {
    reportsFilters(value) {
      this.modelFilters = JSON.parse(JSON.stringify(value));
    },
  },
  methods: {
    ...mapActions([
      "setAlerta",
      "setUltimaTela",
      "setReportsQuery",
      "setReportsFilters",
    ]),
    parseDate(value) {
      if (value) return moment(value).format("DD/MM/YYYY");
      return value;
    },
    setTextSearch(value) {
      if (value.key && value.key === "Enter") {
        this.applyFilters();
      }
    },
    isObjectFilter(property, value) {
      return isObjectFilter(property, value)
    },
    closeChip(property) {
      if (property == 'imagens') {
        this.modelFilters[property] = false;
      } else if (property == 'direcionados') {
        this.modelFilters[property] = false;
      } else if (property == 'reconvocados') {
        this.modelFilters[property] = false;
      } else if (property == 'pendentes') {
        this.modelFilters[property] = false;
      } else if (property == 'revisoes') {
        this.modelFilters[property] = false;
      } else {
        this.modelFilters[property] = null;
      }
      this.applyFilters()
    },
    closeArrayChip(value, index) {
      value.splice(index, 1);
      this.applyFilters()
    },
    changeTipoRelatorio() {
      this.tipoRelatorio = this.modelFilters.tipoRelatorio.tipo;
      this.titulo = this.modelFilters.tipoRelatorio.nome;
    },
    limpar() {
      this.modelFilters = {
        periodo: null,
        dataInicial: null,
        dataFinal: null,

        turnoPeriodo: null,
        turnoHoraInicial: null,
        turnoHoraFinal: null,
        showFilters: true,
      };
    },
    async applyFilters() {
      const self = this;
      const reportsFilters = await getExameFilters(self.modelFilters);
      self.modelFilters = JSON.parse(JSON.stringify(reportsFilters));
      self.setReportsFilters(reportsFilters);
      self.menu = false;
    },
    async getFilters() {
      this.modelFilters = await getExameFilters(this.reportsFilters);
      this.applyFilters();
    },
  },
  created() {
    this.getFilters();
  },
};
</script>

<style scoped>
</style>