import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/axios'
import MultiFiltersPlugin from './plugins/MultiFilters'
import VueTheMask from 'vue-the-mask'
import _ from 'lodash';
import moment from 'moment'

import * as echarts from "echarts";
import walden from "./walden.json";
echarts.registerTheme("walden", walden);

Object.defineProperty(Vue.prototype, '$_', { value: _ });
Object.defineProperty(Vue.prototype, '$moment', { value: moment });
if (!Vue.prototype.$instanceId){
  Object.defineProperty(Vue.prototype, '$instanceId', { value: Math.floor(Math.random() * 1000000) });
  Object.defineProperty(Vue.prototype, '$UserPreferencesinstanceName', { value: `userPreferences_${Vue.prototype.$instanceId}` });
}

Vue.config.productionTip = false

window.addEventListener("beforeunload", function (e)
{
  if (e)
    console.log(e);

  window.sessionStorage.clear();

  return null;
});

Vue.prototype.$window = window;

router.afterEach((to /*, from*/ ) => {
  //console.log('afterEach', to)
  if (to.path.indexOf('login') !== -1) {
    store.dispatch('setLoginLayout')
  } else {
    store.dispatch('setAppLayout')
  }
})

Vue.use(MultiFiltersPlugin);
Vue.use(VueTheMask)


export const bus = new Vue();


const defaultBackgroundColor = '#F0F0F0'

// Initialize the custom-background directive.
export const MarkLineVisitorHistory = {
  bind(el, binding, vnode) {
    // Allow users to customise the color by passing an expression.
    const color = binding.expression || defaultBackgroundColor
    console.log(vnode)
    console.log(color)
    // el might not be present for server-side rendering.
    if (el) {
      console.log(el);
      el.classList.add('selected');
    }
  }
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

