<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row>
      <v-col md="6" cols="12">
        <v-autocomplete
          dense
          rounded
          filled
          :items="items"
          v-model="periodo"
          :hide-details="true"
          @change="onPeriodoChange"
          :label="labelPeriodo ? `${labelPeriodo}` : 'Período de Tempo'"
          item-text="nome"
          item-value="id"
          return-object
          chips
          deletable-chips
        >
        </v-autocomplete>
      </v-col>

      <v-col md="3" cols="12">
        <v-menu
          v-model="dataInicialShow"
          offset-y
          min-width="290px"
          :nudge-right="40"
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              filled
              rounded
              v-on="on"
              readonly
              clearable
              append-icon="mdi-calendar"
              :hide-details="true"
              :label="labelDataInicial ? `${labelDataInicial}` : 'Data Inicial'"
              v-model="dataInicial"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dataInicialFormated"
            @input="dataInicialShow = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="3" cols="12">
        <v-menu
          v-model="dataFinalShow"
          offset-y
          min-width="290px"
          :nudge-right="40"
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              filled
              rounded
              v-on="on"
              readonly
              clearable
              append-icon="mdi-calendar"
              :hide-details="true"
              :label="labelDataFinal ? `${labelDataFinal}` : 'Data Final'"
              v-model="dataFinal"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dataFinalFormated"
            @input="dataFinalShow = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  name: "periodo-filter",

  props: {
    value: {
      type: Object,
      default: () => null,
    },
    labelPeriodo: {
      type: String,
      default: () => "",
    },
    labelDataInicial: {
      type: String,
      default: () => "",
    },
    labelDataFinal: {
      type: String,
      default: () => "",
    },
  },

  data() {
    return {
      items: [
        { id: "HOJE", nome: "HOJE" },
        { id: "ONTEM", nome: "ONTEM" },
        { id: "ULTIMOS_03_DIAS", nome: "ÚLTIMOS 3 DIAS" },
        { id: "ULTIMOS_07_DIAS", nome: "ÚLTIMOS 7 DIAS" },
        { id: "ULTIMOS_10_DIAS", nome: "ÚLTIMOS 10 DIAS" },
        { id: "ULTIMOS_30_DIAS", nome: "ÚLTIMOS 30 DIAS" },
        { id: "ULTIMOS_02_MESES", nome: "ÚLTIMOS 2 MESES" },
        { id: "ULTIMOS_03_MESES", nome: "ÚLTIMOS 3 MESES" },
        { id: "ULTIMOS_06_MESES", nome: "ÚLTIMOS 6 MESES" },
      ],
      periodo: this.value.periodo,
      dataInicial: this.value.dataInicial,
      dataInicialShow: "",
      dataInicialFormated: "",
      dataFinal: this.value.dataFinal,
      dataFinalShow: "",
      dataFinalFormated: "",
    };
  },

  watch: {
    value(value) {
      this.periodo = value.periodo;
      this.dataInicial = value.dataInicial;
      this.dataFinal = value.dataFinal;
    },

    periodo(value) {
      this.onPeriodoChange(value);
    },
    dataInicial(value) {
      if (value)
        this.dataInicialFormated = moment(value, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );
      this.$emit("input", { ...this.value, dataInicial: value });
    },
    dataFinal(value) {
      if (value)
        this.dataFinalFormated = moment(value, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );
      this.$emit("input", { ...this.value, dataFinal: value });
    },

    dataInicialFormated() {
      if (this.dataInicialFormated)
        this.dataInicial = moment(
          this.dataInicialFormated,
          "YYYY-MM-DD"
        ).format("DD/MM/YYYY");
    },
    dataFinalFormated() {
      if (this.dataFinalFormated)
        this.dataFinal = moment(this.dataFinalFormated, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
    },
  },

  methods: {
    onPeriodoChange(periodoChange) {
      switch (periodoChange?.id) {
        case "HOJE":
          this.dataInicialFormated = moment().format("YYYY-MM-DD");
          this.dataFinalFormated = moment().format("YYYY-MM-DD");
          break;
        case "ONTEM":
          this.dataInicialFormated = moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD");
          this.dataFinalFormated = moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD");
          break;
        case "ULTIMOS_03_DIAS":
          this.dataInicialFormated = moment()
            .subtract(3, "days")
            .format("YYYY-MM-DD");
          this.dataFinalFormated = moment().format("YYYY-MM-DD");
          break;
        case "ULTIMOS_07_DIAS":
          this.dataInicialFormated = moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD");
          this.dataFinalFormated = moment().format("YYYY-MM-DD");
          break;
        case "ULTIMOS_10_DIAS":
          this.dataInicialFormated = moment()
            .subtract(10, "days")
            .format("YYYY-MM-DD");
          this.dataFinalFormated = moment().format("YYYY-MM-DD");
          break;
        case "ULTIMOS_30_DIAS":
          this.dataInicialFormated = moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD");
          this.dataFinalFormated = moment().format("YYYY-MM-DD");
          break;
        case "ULTIMOS_02_MESES":
          this.dataInicialFormated = moment()
            .subtract(2, "months")
            .format("YYYY-MM-DD");
          this.dataFinalFormated = moment().format("YYYY-MM-DD");
          break;
        case "ULTIMOS_03_MESES":
          this.dataInicialFormated = moment()
            .subtract(3, "months")
            .format("YYYY-MM-DD");
          this.dataFinalFormated = moment().format("YYYY-MM-DD");
          break;
        case "ULTIMOS_06_MESES":
          this.dataInicialFormated = moment()
            .subtract(6, "months")
            .format("YYYY-MM-DD");
          this.dataFinalFormated = moment().format("YYYY-MM-DD");
          break;

        default:
          this.dataInicialFormated = "";
          this.dataFinalFormated = "";
          break;
      }

     this.$emit("input", { ...this.value, periodo: periodoChange });
    },
  },

  created() {
    // if (!this.value.dataInicial && !this.value.dataFinal) {
    //   this.onPeriodoChange(this.value.periodo);
    // }
  },
};
</script>

