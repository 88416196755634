<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row>
      <v-col md="6" cols="12">
        <v-autocomplete
          dense
          rounded
          filled
          :items="items"
          v-model="turnoPeriodo"
          :hide-details="true"
          @change="onPeriodoChange"
          :label="labelPeriodo"
          item-text="nome"
          item-value="id"
          return-object
          clearable
        >
        </v-autocomplete>
      </v-col>

      <v-col md="3" cols="12">
        <v-menu
          v-model="horaInicialShow"
          offset-y
          min-width="290px"
          :nudge-right="40"
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              filled
              rounded
              v-on="on"
              clearable
              v-mask="'##:##'"
              :rules="ruleTime"
              append-icon="mdi-clock-time-four-outline"
              :hide-details="true"
              :label="labelHoraInicial"
              v-model="turnoHoraInicial"
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="turnoHoraInicial"
            format="24hr"
            @input="horaInicialShow = false"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col md="3" cols="12">
        <v-menu
          v-model="horaFinalShow"
          offset-y
          min-width="290px"
          :nudge-right="40"
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              filled
              rounded
              v-on="on"
              clearable
              v-mask="'##:##'"
              :rules="ruleTime"
              append-icon="mdi-clock-time-four-outline"
              :hide-details="true"
              :label="labelHoraFinal"
              v-model="turnoHoraFinal"
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="turnoHoraFinal"
            format="24hr"
            @change="horaFinalShow = false"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  name: "turno-filter",

  props: {
    value: {
      type: Object,
      default: () => null,
    },
    labelPeriodo: {
      type: String,
      default: () => "Turno",
    },
    labelHoraInicial: {
      type: String,
      default: () => "Hora Inicial do Turno",
    },
    labelHoraFinal: {
      type: String,
      default: () => "Hora Final do Turno",
    },
  },

  data() {
    return {
      items: [
        { id: "MAT", nome: "Matutino" },
        { id: "VES", nome: "Vespertino" },
        { id: "NOT", nome: "Noturno" },
      ],
      turnoPeriodo: this.value.turnoPeriodo,
      turnoHoraInicial: this.value.turnoHoraInicial,
      horaInicialShow: "",
      turnoHoraFinal: this.value.turnoHoraFinal,
      horaFinalShow: "",

      ruleTime: [
        (v) =>
          !v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || "Hora incorreta",
      ],
    };
  },

  watch: {
    value(value) {
      this.turnoPeriodo = value.turnoPeriodo;
      this.turnoHoraInicial = value.turnoHoraInicial;
      this.turnoHoraFinal = value.turnoHoraFinal;
    },
    turnoPeriodo() {
      this.emitModel();
    },
    turnoHoraInicial() {
      this.emitModel();
    },
    turnoHoraFinal() {
      this.emitModel();
    },
  },

  methods: {
    onPeriodoChange(periodoChange) {
      switch (periodoChange?.id) {
        case "MAT":
          this.turnoHoraInicial = moment("06:00:00", "HH:mm").format("HH:mm");
          this.turnoHoraFinal = moment("12:00:00", "HH:mm").format("HH:mm");
          break;
        case "VES":
          this.turnoHoraInicial = moment("12:00:00", "HH:mm").format("HH:mm");
          this.turnoHoraFinal = moment("18:00:00", "HH:mm").format("HH:mm");
          break;
        case "NOT":
          this.turnoHoraInicial = moment("18:00:00", "HH:mm").format("HH:mm");
          this.turnoHoraFinal = moment("06:00:00", "HH:mm").format("HH:mm");
          break;

        default:
          this.turnoHoraInicial = null;
          this.turnoHoraFinal = null;
          break;
      }
    },
    emitModel() {
      this.$emit("input", {
        ...this.value,
        turnoPeriodo: this.turnoPeriodo,
        turnoHoraInicial: this.turnoHoraInicial,
        turnoHoraFinal: this.turnoHoraFinal,
      });
    },
  },
};
</script>