import Vue from 'vue'
import axios from 'axios'

Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios.create({
      baseURL: process.env.VUE_APP_MEZZOW_GATEWAY_URL,
      timeout: 360000
    })
    Vue.prototype.$http.interceptors.request.use((config) => {
      const token = localStorage.getItem('tokenMZ2')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    }, (err) => {
      return Promise.reject(err)
    })
    Vue.prototype.$auth = axios.create({
      baseURL: process.env.VUE_APP_MEZZOW_SEGURANCA_URL,
      timeout: 360000
    })
    Vue.prototype.$auth.interceptors.request.use((config) => {
      const token = localStorage.getItem('tokenMZ2')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    }, (err) => {
      return Promise.reject(err)
    })
    Vue.prototype.$storage = axios.create({
      baseURL: process.env.VUE_APP_MEZZOW_STORAGE_URL,
      timeout: 360000
    })
    Vue.prototype.$storage.interceptors.request.use((config) => {
      const token = localStorage.getItem('tokenMZ2')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    }, (err) => {
      return Promise.reject(err)
    })

    Vue.prototype.$googlestorage = axios.create({
      baseURL: "https://storage.googleapis.com",
      timeout: 360000
    });
  
  }


})
