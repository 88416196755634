<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card flat>
      <v-card-text>
        <v-autocomplete
          rounded
          filled
          dense
          label="Selecionar Filtro Favorito"
          :items="items"
          v-model="selected"
          item-value="id"
          :item-text="'nome'"
          clearable
          return-object        
          :hide-details="true"
          @change="onChange"
        >
          <template v-slot:append>
            <v-tooltip open-delay="800" top v-if="selected && !filterDeleteOff">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"
                  color="primary"
                  @click="removeItemDialog = true"
                >mdi-delete-outline</v-icon>
              </template>
              <span>Excluir Selecionado(s)</span>
            </v-tooltip>
          </template>
        </v-autocomplete>
      </v-card-text>
    </v-card>
    <v-dialog v-model="removeItemDialog" persistent max-width="450">
      <v-card>
        <v-card-title>{{confirm.title}}</v-card-title>
        <v-card-text>{{confirm.contentHtml}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text class="red--text darken-1" @click.native="removeItemDialog = false">{{ confirm.cancel }}</v-btn>
          <v-btn text class="green--text darken-1" @click.native="removeItem()">{{ confirm.ok }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "favorite-filter-select",

  props: {
    type: {
      type: String,
      default: "MZW2_EXAME_LIST",
      required: true,
    },
    filterSelectOff: {
      type: Boolean,
      default: false
    },
    filterDeleteOff: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      items: [],
      selected: null,
      modelFilters: null,
      removeItemDialog: false,
      confirm: {
        title: "Atenção",
        contentHtml: "Tem certeza que deseja apagar o filtro selecionado.",
        ok: "Sim",
        cancel: "Não"
      },
    };
  },
  watch: {
    updateFavoriteModel(value) {
      if (value) {
        this.getFavoriteFilters()
        this.setUpdateFavoriteModel(false)
      }
    },
    exameFilters() {
      this.loadFavoriteFiltered()
    }
  },
  computed: {
    ...mapGetters(["exameFilters","updateFavoriteModel"]),
  },
  methods: {
    ...mapActions(["setAlerta","setExameFilters","setUpdateFavoriteModel"]),
    onChange(value) {
      if (value && value.valor) {
        if (!this.filterSelectOff) {
          this.modelFilters = JSON.parse(value.valor)

          if (this.type == "MZW2_EXAME_LIST") {
            this.setExameFilters(this.modelFilters)
          } else {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Tipo de filtro não encontrado",
            });
          }
        }
      }
      this.$emit("return-value", value)
    },
    removeItem() {
      const self = this
      self.$http.delete(`/favorito/${this.selected.id}`)
        .then(() => {
          self.setAlerta({
            tipoAlerta: "success",
            textAlerta: "Filtro removido com sucesso",
          });
          self.getFavoriteFilters()
          self.selected = null
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Falha ao remover o filtro selecionado",
          });
        })
      self.removeItemDialog = false
    },
    getFavoriteFilters() {
      const self = this;
      self.$http
        .get(`/favorito`)
        .then((response) => {
          self.items = response.data.filter(f => f.situacao === true && f.tipo === this.type);
          self.loadFavoriteFiltered()
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Favoritos",
          });
        });
    },
    loadFavoriteFiltered() {
      this.selected = this.items.find(item => JSON.parse(item.valor)?.urlFilter == this.exameFilters?.urlFilter)
      this.$emit("return-value", this.selected)
    },
  },
  created() {
    this.getFavoriteFilters()
  }
};
</script>