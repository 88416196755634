<template>
  <v-app>
    <v-app-bar app flat clipped-left clipped-right height="64">
      <v-app-bar-nav-icon @click="changeDrawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="pr-3 pb-2 pl-0">
        <v-img
          class="mt-2"
          width="150"
          contain
          :src="
            this.$vuetify.theme.dark
              ? require('@/assets/logo-dark.svg')
              : require('@/assets/logo.svg')
          "
        >
        </v-img>
        <!-- <span class="subtitle-1" v-text="nomeExibicao"></span> -->
      </v-toolbar-title>

      <v-row justify="space-around">
        <v-col cols="12" md="10" class="py-0">
          <v-hover v-slot="{ hover }">
            <div>
              <exame-filter v-if="exameFilters.showFilters" />
              <reports-filter v-else-if="reportsFilters.showFilters" />
              <div v-show="hover">
                <v-text-field
                  solo
                  flat
                  dense
                  readonly
                  :hide-details="true"
                  v-show="isShowFilterComponent"
                  append-icon="mdi-filter-variant"
                  @click="openFilters"
                  @click:append="openFilters"
                >
                  <template v-slot:prepend-inner>
                    <v-chip
                      class="ma-1"
                      v-if="txtFilter != ''"
                      close
                      @click:close="limpar()"
                      >{{
                        isMobile
                          ? txtFilter.substring(0, 21) + " ..."
                          : txtFilter
                      }}</v-chip
                    >
                  </template>
                </v-text-field>
              </div>
              <div v-show="!hover">
                <v-text-field
                  solo
                  flat
                  dense
                  readonly
                  :hide-details="true"
                  v-show="isShowFilterComponent"
                  v-model="txtFilter"
                  append-icon="mdi-filter-variant"
                  @click="openFilters"
                  @click:append="openFilters"
                >
                </v-text-field>
              </div>
            </div>
          </v-hover>
        </v-col>
      </v-row>

      <notification-list />
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      :expand-on-hover="expandOnHover"
      :mini-variant.sync="miniVariant"
      mobile-breakpoint="1264"
      height="100%"
      clipped
      app
    >
      <AlterarSenha
        :perfilId="securityPerfilId"
        :nomeUsuario="nomeExibicao"
        :miniVariant="miniVariant"
      ></AlterarSenha>

      <v-list dense shaped class="">
        <div v-for="item in menuItems" :key="item.title">
          <v-list-item v-if="item.url" :key="item.title" link :to="item.url">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div v-else>
            <v-list-group :key="item.title">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                :class="{ 'pl-10': !miniVariant }"
                v-for="subItem in item.sub"
                :key="subItem.title"
                link
                :to="subItem.url"
              >
                <v-list-item-icon>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </div>
        <v-list-group
          :eager="dialogChat"
          v-model="dialogChat"
          v-if="showMezzowChat"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-chat-processing-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Contatos Chat </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item active="dialogChat" @click="dialogChat = !dialogChat">
            <iframe
              id="chat-contatos"
              name="chat-contatos"
              style="border: none"
              width="100%"
              :height="chatContatosHeight"
              allow="autoplay"
              :src="urlChat"
            ></iframe>
          </v-list-item>
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <v-divider></v-divider>
        <v-list dense shaped subheader>
          <v-list-item v-if="isAdminPermission" :href="webAdminUrl" target="_blank">
            <v-list-item-icon>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Acesso Admin</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="changeTheme">
            <v-list-item-icon>
              <v-icon>mdi-invert-colors</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Alterar Tema</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <div id="desktop-chat">
      <template v-for="(chatAberto, i) in chatsAbertos">
        <div
          class="wrapper-chat"
          :id="`chat_${chatAberto.htmlId}`"
          :key="`chat_${i}`"
          style="width: 370.85px"
        >
          <div
            :id="`c_${chatAberto.perfilIdAmigo}`"
            class="janela-chat"
            :style="chatAberto.style"
          >
            <iframe
              id="chat-contatos"
              scrolling="no"
              name="chat-contatos"
              style="border: none"
              width="100%"
              height="100%"
              :src="chatAberto.urlChatAmigo"
            />
          </div>
        </div>
      </template>
    </div>
  </v-app>
</template>

<script>
import { bus } from "../../main";
import { routes } from "../../router/index";
import {
  getPerfilId,
  storeUserPreferences,
  possuiPermissao,
  logOut,
  getNomeExibicao,
  isAdminMaster,
  isAdminClient,
  isAdminAuxiliar,
} from "../../seguranca";
import AlterarSenha from "@/components/comum/AlterarSenha";
import ExameFilter from "../exame/ExameFilter.vue";
import ReportsFilter from "../relatorio/ReportsFilter.vue";
import { mapActions, mapGetters } from "vuex";
import NotificationList from '../../components/NotificationList.vue';

export default {
  name: "App",
  components: {
    AlterarSenha,
    ExameFilter,
    ReportsFilter,
    NotificationList,
  },
  computed: {
    ...mapGetters(["exameFilters", "reportsFilters"]),
    chatContatosHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "460px";
        case "sm":
          return "460px";
        case "md":
          return "460px";
        case "lg":
          return "460px";
        case "xl":
          return "460px";
      }
      return "460px";
    },
    isAdminPermission() {
      return isAdminClient() || isAdminMaster() || isAdminAuxiliar();
    },
    webAdminUrl() {
      return `${process.env.VUE_APP_MEZZOW_WEB_ADMIN_URL}`;
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  data: () => ({
    showMezzowChat: false,
    urlChat: "",
    //chatRootUrl:'http://localhost:3000',
    chatRootUrl: "https://videochat.mezzow.com",
    chatsAbertos: [],
    dialogChat: true,
    drawer: () => this.$vuetify.breakpoint.lgAndUp,
    txtFilter: "",
    menuItems: [],
    miniVariant: false,
    expandOnHover: false,
    securityPerfilId: getPerfilId(),
    nomeExibicao: getNomeExibicao() != null ? getNomeExibicao() : "",
    isShowFilterComponent: false,
    userPreferences: [
      {
        login: "",
        preferences: [
          {
            "agendamento-list": {},
          },
        ],
      },
    ],
  }),
  methods: {
    ...mapActions(["setExameFilters"]),
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    logout() {
      //window.localStorage.removeItem('tokenMZ2')
      this.$vuetify.theme.dark = false;
      logOut();
      this.removeFiltersFromStorage();
      this.$router.push({
        path: "/login",
        query: {
          redirect: "/",
        },
      });
    },
    removeFiltersFromStorage() {
      // Filtros da tela de atendimento;
      window.localStorage.removeItem("atendimentoFilterStorage");

      // Filtros da tela de agendamento;
      window.localStorage.removeItem("start_date");
      window.localStorage.removeItem("end_date");
      window.localStorage.removeItem("nomePacienteElemento");
      window.localStorage.removeItem("cpfPacienteForm");
      window.localStorage.removeItem("unidadePacienteForm");
      window.localStorage.removeItem("statusPacienteForm");
    },
    createMenuByPermissions() {
      // Home removido do menu;
      // this.menuItems = Object.assign(
      //   [{}],
      //   [{ title: "Home", icon: "mdi-home", url: "/" }]
      // );

      // if (
      //   possuiPermissao(
      //     routes
      //       .filter((r) => r.path === "/agendamento/list")
      //       .map((r) => r.meta.roles)[0]
      //   )
      // ) {
      //   this.menuItems.push({
      //     title: "Agendamento",
      //     icon: "mdi-alarm",
      //     url: "/agendamento/list",
      //   });
      // }

      // if (
      //   possuiPermissao(
      //     routes
      //       .filter((r) => r.path === "/atendimento/list")
      //       .map((r) => r.meta.roles)[0]
      //   )
      // ) {
      //   this.menuItems.push({
      //     title: "Atendimento",
      //     icon: "mdi-medical-bag",
      //     url: "/atendimento/list",
      //   });
      // }

      if (
        possuiPermissao(
          routes
            .filter((r) => r.path === "/exame/list")
            .map((r) => r.meta.roles)[0]
        )
      ) {
        this.menuItems.push({
          title: "Exame",
          icon: "mdi-card-bulleted-settings",
          url: "/exame/list",
        });
      }

      if (
        possuiPermissao(
          routes
            .filter((r) => r.path === "/relatorioAtendimentoExport/new")
            .map((r) => r.meta.roles)[0]
        )
      ) {
        // this.menuItems.push({
        //   title: "Relatório Atendimento",
        //   icon: "mdi-file-chart",
        //   sub: [
        //     {
        //       title: "Exportação",
        //       icon: "mdi-file-export",
        //       url: "/relatorioAtendimentoExport/new",
        //     },
        //   ],
        // });
        this.menuItems.push({
          title: "Relatórios",
          icon: "mdi-file-chart",
          url: "/relatorioAtendimentoExport/new",
        });
      }

      if (
        possuiPermissao(
          routes
            .filter((r) => r.path === "/autoTexto/list")
            .map((r) => r.meta.roles)[0]
        )
      ) {
        this.menuItems.push({
          title: "Auto Texto",
          icon: "mdi-account-file-text",
          url: "/autoTexto/list",
        });
      }
    },
    openFilters() {
      console.log("open");
      bus.$emit("clickFilter");
      console.log("emitido");
    },
    limpar() {
      console.log("limpar event");
      bus.$emit("limparClickFilter");
    },
    changeDrawer() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.drawer = true;
        this.miniVariant = !this.miniVariant;
        this.expandOnHover = !this.expandOnHover;
      } else {
        this.drawer = true;
        this.miniVariant = false;
        this.expandOnHover = false
      }
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    globalSearchFilterClicked(objFilter) {
      console.log("globalSearchFilterClicked ---------------------------- >");
      console.log(objFilter);
      this.txtFilter = objFilter;
    },
    hideFilterComponent() {
      this.isShowFilterComponent = false;
    },
    showFilterComponent() {
      this.isShowFilterComponent = true;
    },
    storeLastRestCallPreferences(obj) {
      storeUserPreferences(
        obj.componentName,
        "last-rest-call",
        obj.value,
        obj.options
      );
    },
    storeLastListClickIdPreferences(obj) {
      storeUserPreferences(
        obj.componentName,
        "last-list-click-temporary",
        obj.value,
        obj.options
      );
    },
    openWindowChatByFriendId(perfilId) {
      var urlChatAmigo = `${
        this.chatRootUrl
      }/#/chat/${getPerfilId()}/${perfilId}?mezzow2Token=${window.localStorage.getItem(
        "tokenMZ2"
      )}`;

      var jaExisteChatAberto =
        this.chatsAbertos.filter((obj) => obj.perfilIdAmigo === perfilId)
          .length > 0;

      if (jaExisteChatAberto === true) {
        this.chatsAbertos
          .filter((obj) => obj.perfilIdAmigo === perfilId)
          .forEach((ele) => {
            ele.style = `width: 360.85px;`;
          });
      } else {
        this.chatsAbertos.push({
          htmlId: this.uuidv4(),
          urlChatAmigo: `${urlChatAmigo}`,
          perfilId: getPerfilId(),
          perfilIdAmigo: perfilId,
          token: window.localStorage.getItem("tokenMZ2"),
          style: `width: 360.85px;`,
        });
      }
    },
    closeWindowChatByFriendId(perfilId) {
      var self = this;
      this.chatsAbertos
        .map((ca, index) => {
          return { ca: ca, posicao: index };
        })
        .filter((obj) => obj.ca.perfilIdAmigo === perfilId)
        .forEach((ele) => {
          var domEle = document.getElementById("chat_" + ele.ca.htmlId);
          if (domEle) {
            domEle.remove();
          }
          self.chatsAbertos.splice(ele.posicao, 1);
        });
    },
    minimizeWindowChatByFriendId(perfilId) {
      this.chatsAbertos.forEach((ele) => {
        if (ele.perfilIdAmigo === perfilId) {
          ele.style = `width: 360.85px; top: -40px;`;
        }
      });
    },
    restoreWindowChatByFriendId(perfilId) {
      this.chatsAbertos.forEach((ele) => {
        if (ele.perfilIdAmigo === perfilId) {
          ele.style = `width: 360.85px;`;
        }
      });
    },
    formatJson(stringJson) {
      var json = JSON.parse(stringJson);
      if (!json.data.acao && json.data.id) {
        json.data.acao = "abrir-chat-amigo";
      }

      return json;
    },
    mezzowChatEventListener(event) {
      if (event.origin !== this.chatRootUrl) return;

      if (event.data) {
        var json = this.formatJson(event.data);

        console.log(json.data.acao);
        switch (json.data.acao) {
          case "abrir-chat-amigo":
            this.openWindowChatByFriendId(json.data.id);
            break;
          case "fechar":
            this.closeWindowChatByFriendId(json.data.id);
            break;
          case "minimizar":
            this.minimizeWindowChatByFriendId(json.data.id);
            break;
          case "restaurar":
            this.restoreWindowChatByFriendId(json.data.id);
            break;
          case "tocar-som ":
            this.restoreWindowChatByFriendId(json.data.id);
            break;
          default:
            break;
        }
      }
    },
  },
  mounted() {
    window.addEventListener("message", this.mezzowChatEventListener, false);
    var perfilId = getPerfilId();
    var token = window.localStorage.getItem("tokenMZ2");
    this.urlChat = `${this.chatRootUrl}/#/contatos/${perfilId}?mezzow2Token=${token}`;
  },
  created() {
    this.createMenuByPermissions();

    bus.$on("clickSearchFilter", this.globalSearchFilterClicked);
    bus.$on("last-rest-call", this.storeLastRestCallPreferences);
    bus.$on("last-list-click-temporary", this.storeLastListClickIdPreferences);
    bus.$on("hideFilterComponent", this.hideFilterComponent);
    bus.$on("showFilterComponent", this.showFilterComponent);
  },
  destroyed() {
    this.removeFiltersFromStorage();
  },
};
</script>
<style scoped src="@/assets/window-chat.css"></style>
<style>
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}
</style>
