import Vue from 'vue'
import Vuex from 'vuex'

import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import moment from 'moment'

Vue.use(Vuex)

const state = {
  layout: 'app-layout',
  ultimaTela: '',
  alerta: '',

  agendamentoQuery: '',
  agendamentoRefreshList: '',
  agendamentoLastItemId: null,

  atendimentoQuery: '',
  atendimentoRefreshList: '',
  atendimentoLastItemId: null,
  relatorioAtendimentoExportQuery: '',

  exameQuery: '',
  exameRefreshList: '',
  exameLastItemId: null,
  exameFilters: {
    textFilter: "",
    urlFilter: "",
    periodo: null,
    dataInicial: moment().subtract(1, "month").format("DD/MM/yyyy"),
    dataFinal: null,
    pacienteNome: null,
    grupoEmpresa: null,
    empresa: null,
    unidade: null,
    setor: null,
    modalidade: null,
    grupoEspecialidade: null,
    especialidade: null,
    tipoExame: null,
    medicoRadiologista: null,
    medicoLaudo: null,
    medicoDirecionamento: null,
    statusExame: null,
    solicitacaoPrioridade: null,
    imagens: false,
    direcionados: false,
    reconvocados: false,
    pendentes: false,
    revisoes: false,
    showFilters: false
  },

  reportsQuery: "",
  reportsFilters: {
    periodo: null,
    dataInicial: moment().subtract(1, "week").format("DD/MM/yyyy"),
    dataFinal: null,
    turnoPeriodo: null,
    turnoHoraInicial: null,
    turnoHoraFinal: null,
    imagens: false,
    direcionados: false,
    reconvocados: false,
    pendentes: false,
    revisoes: false,
    showFilters: false
  },

  updateFavoriteModel: false,
  notifications: [],

  autoTextoQuery: '',
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
