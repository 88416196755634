import Vue from 'vue'
import VueRouter from 'vue-router'

import {
  isLogged,
  // getPermissao
} from '../seguranca'

Vue.use(VueRouter)

function loadView(view) {
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  return () => import( /* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: loadView('Login'),
    meta: {roles: []},
  },
  {
    path: "/editor",
    name: "EditorTeste",
    component: loadView("EditorTeste"),
    meta: { roles: [] }
  },  
  // Caminho para Home comentado...
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: loadView('Home'),
  //   meta: {roles: []},
  //   beforeEnter: isLogged
  // },
  // Redirecionamento da raiz para exame/list
  {
    path: '/',
    name: 'Exame',
    redirect: '/exame/list',
    meta: {roles: []},
    beforeEnter: isLogged
  },
  {
    path: '/teleconsulta',
    name: 'Teleconsulta',
    component: loadView('atendimento/TeleconsultaList'),
    meta: {roles: ["ROLE_ADMIN"]},
    beforeEnter: isLogged
  },
  {
    path: '/relatorio-consulta',
    name: 'RelatorioConsulta',
    component: loadView('atendimento/RelatorioConsulta'),
    meta: {roles: ["ROLE_ADMIN"]},
    beforeEnter: isLogged
  },
  {
    path: '/telelaudo',
    name: 'Telelaudo',
    component: loadView('exame/TelelaudoList'),
    meta: {roles: ["ROLE_ADMIN"]},
    beforeEnter: isLogged
  },
  {
    path: '/agendamento/list',
    name: 'AgendamentoList',
    component: loadView('agendamento/AgendamentoList'),
    meta: {roles: ["ROLE_ADMIN"]},
    beforeEnter: isLogged
  }, 
  {
    path: '/agendamento/new',
    name: 'AgendamentoCreate',
    component: loadView('agendamento/AgendamentoCreateEdit'),
    meta: {roles: ["ROLE_ADMIN"]},
    beforeEnter: isLogged
  }, 
  {
    path: '/agendamento/edit/:id',
    name: 'AgendamentoEdit',
    component: loadView('agendamento/AgendamentoCreateEdit'),
    meta: {roles: ["ROLE_ADMIN"]},
    beforeEnter: isLogged
  },
  {
    path: '/atendimento/list',
    name: 'AtendimentoList',
    component: loadView('atendimento/AtendimentoList'),
    meta: {roles: ["ROLE_ADMIN"]},
    beforeEnter: isLogged
  }, 
  {
    path: '/atendimento/new',
    name: 'AtendimentoCreate',
    component: loadView('atendimento/AtendimentoCreateEdit'),
    meta: {roles: ["ROLE_ADMIN"]},
    beforeEnter: isLogged
  }, 
  {
    path: '/atendimento/edit/:id',
    name: 'AtendimentoEdit',
    component: loadView('atendimento/AtendimentoCreateEdit'),
    meta: {roles: ["ROLE_ADMIN"]},
    beforeEnter: isLogged
  },
  {
    path: '/registroPosConsulta/list',
    name: 'RegistroPosConsultaList',
    component: loadView('atendimento/RegistroPosConsultaList'),
    meta: {roles: ["ROLE_ADMIN"]},
    beforeEnter: isLogged
  }, 
  {
    path: '/registroPosConsulta/new',
    name: 'RegistroPosConsultaCreate',
    component: loadView('atendimento/RegistroPosConsultaCreateEdit'),
    meta: {roles: ["ROLE_ADMIN"]},
    beforeEnter: isLogged
  }, 
  {
    path: '/registroPosConsulta/edit/:id',
    name: 'RegistroPosConsultaEdit',
    component: loadView('atendimento/RegistroPosConsultaCreateEdit'),
    meta: {roles: ["ROLE_ADMIN"]},
    beforeEnter: isLogged
  },
  {
    path: '/relatorioAtendimentoExport/new',
    name: 'RelatorioAtendimentoExportCreate',
    component: loadView('relatorio/RelatorioAtendimentoExportCreateEdit'),
    meta: {roles: ["ROLE_ADMIN","ROLE_CLIENTE_ADMIN","ROLE_AUXILIAR_ADMIN"]},
    beforeEnter: isLogged
  },
  {
    path: '/exame/list',
    name: 'ExameList',
    component: loadView('exame/ExameList'),
    beforeEnter: isLogged,
    meta: {roles: ["ROLE_ADMIN","ROLE_CLIENTE_ADMIN","ROLE_MEDICO_ATENDIMENTO","ROLE_MEDICO_RADIOLOGISTA","ROLE_AUXILIAR_ADMIN","ROLE_DIGITADOR"]},
  }, 
  {
    path: '/exame/new',
    name: 'ExameCreate',
    component: loadView('exame/ExameCreateEdit'),
    beforeEnter: isLogged,
    meta: {roles: ["ROLE_ADMIN","ROLE_MEDICO_ATENDIMENTO","ROLE_MEDICO_RADIOLOGISTA"]},
  }, {
    path: '/exame/edit/:id',
    name: 'ExameEdit',
    component: loadView('exame/ExameCreateEdit'),
    beforeEnter: isLogged,
    meta: {roles: ["ROLE_ADMIN","ROLE_MEDICO_ATENDIMENTO","ROLE_MEDICO_RADIOLOGISTA"]},
  },
  {
    path: '/autoTexto/list',
    name: 'AutoTextoList',
    component: loadView('editor/AutoTextoList'),
    beforeEnter: isLogged,
    meta: {roles: ["ROLE_ADMIN","ROLE_CLIENTE_ADMIN","ROLE_MEDICO_ATENDIMENTO","ROLE_MEDICO_RADIOLOGISTA","ROLE_AUXILIAR_ADMIN"]},
  }, 
  {
    path: '/autoTexto/new',
    name: 'AutoTextoCreate',
    component: loadView('editor/AutoTextoCreateEdit'),
    beforeEnter: isLogged,
    meta: {roles: ["ROLE_ADMIN","ROLE_CLIENTE_ADMIN","ROLE_MEDICO_ATENDIMENTO","ROLE_MEDICO_RADIOLOGISTA","ROLE_AUXILIAR_ADMIN"]},
  }, {
    path: '/autoTexto/edit/:id',
    name: 'AutoTextoEdit',
    component: loadView('editor/AutoTextoCreateEdit'),
    beforeEnter: isLogged,
    meta: {roles: ["ROLE_ADMIN","ROLE_CLIENTE_ADMIN","ROLE_MEDICO_ATENDIMENTO","ROLE_MEDICO_RADIOLOGISTA","ROLE_AUXILIAR_ADMIN"]},
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
export { routes }

// Removida a implementação do beforeEach pois ela ocorre antes do isLogged e apresenta problemas em telas que precisam de permissão.
// router.beforeEach((to, from, next) => {
//   const { roles } = to.meta;
//   if (roles) {
//       if (roles.length && roles.includes(getPermissao()) ) {
//           return next({ path: '/' });
//       }
//   }
//   next();
// })
