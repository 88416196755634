import moment from "moment";

function dateFormat (localDate, patternIn, patternOut) {
  if (!patternOut) patternOut = "YYYY-MM-DD HH:mm:ss" // Define Timestamp default pattern if not patternOut
  return moment(localDate, patternIn).format(patternOut)
}

function dateFormatStartDay (localDate, patternIn, patternOut) {
  if (!patternOut) patternOut = "YYYY-MM-DD HH:mm:ss" // Define Timestamp default pattern if not patternOut
  return moment(localDate, patternIn).startOf("day").format(patternOut)
}

function dateFormatEndDay (localDate, patternIn, patternOut) {
  if (!patternOut) patternOut = "YYYY-MM-DD HH:mm:ss" // Define Timestamp default pattern if not patternOut
  return moment(localDate, patternIn).endOf("day").format(patternOut)
}

function dateDiff(dateIni, dateFin) {
  return moment.duration(moment(dateFin).diff(moment(dateIni)));
}

function isItToday(date) {
  const dateItem = moment(date).locale("pt-BR")
  const dateNow = moment().locale("pt-BR")

  return dateNow.format("DD/MM/YYYY") === dateItem.format("DD/MM/YYYY");
}

function isItThisYear(date) {
  const dateItem = moment(date).locale("pt-BR")
  const dateNow = moment().locale("pt-BR")

  return dateNow.format("YYYY") === dateItem.format("YYYY");
}

function getDateFormatted(date) {
  const dateItem = moment(date).locale("pt-BR")

  if (isItToday(date)) {
    return dateItem.format("HH:mm")
  } else {
    if (isItThisYear(date)) {
      return dateItem.format("DD MMM");
    } else {
      return dateItem.format("DD/MM/YYYY")
    }
  }
}

function getDateFormattedDisplay(date) {
  return moment(date).locale("pt-BR").format("HH:mm")
}

export {
  dateFormat,
  dateFormatStartDay,
  dateFormatEndDay,
  dateDiff,
  isItToday,
  isItThisYear,
  getDateFormatted,
  getDateFormattedDisplay
}