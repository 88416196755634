<template>
  <v-col cols="auto">
    <v-menu
      offset-y
      allow-overflow
      nudge-top="-8"
      min-width="200"
      max-height="80%"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          overlap
          :color="itemsCount > 0 ? 'red' : 'transparent'"
          :content="itemsCount"
        >
          <v-btn v-bind="attrs" v-on="on" :disabled="itemsCount == 0" icon small>
            <v-icon size="30">mdi-bell-outline</v-icon>
          </v-btn>
          <v-snackbar v-model="snackbar" color="success" class="mt-9 mr-10 teste"  right top :timeout="6000">
            <v-row align="center" justify="center">
              <v-col cols="auto">
                {{ message }}
              </v-col>
              <v-btn small text @click.native="snackbar = false">Fechar</v-btn>
            </v-row>
          </v-snackbar>
        </v-badge>
      </template>
      <v-list v-if="itemsCount > 0" class="pt-0">
        <v-list-item class="primary" dark>
          <v-list-item-title>
            <span class="headline">Notificações</span>
          </v-list-item-title>
          <v-list-item-action>
            <v-tooltip open-delay="800" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" icon @click="removeAll">
                  <v-icon>mdi-delete-sweep-outline</v-icon>
                </v-btn>
              </template>
              <span>Apagar todos</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-for="(item, index) in items" :key="index" dense link @click="onClick(item)">
          <v-list-item-avatar>
            <v-avatar color="primary" size="56" class="white--text">
              <v-icon dark>{{ getIcon(item.type) }}</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.message }} - {{ item.data.target || item.data.download }}</v-list-item-title>
            <!-- <v-list-item-subtitle>{{ dateFormat(item.dateRequest) }} - {{ item.source }}</v-list-item-subtitle> -->
            <v-list-item-subtitle>{{ dateFormat(item.dateCreated) }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip open-delay="800" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" icon @click.stop="onRemove(item)">
                  <v-icon color="primary">mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Apagar Notificação</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-col>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "notification-list",
  data () {
    return {
      items: [],
      snackbar: false,
      message: "",
    }
  },
  watch: {
    notifications (value) {
      this.items = [...value] || []
    },
    items (value, state) {
      this.showNotification(value, state)
    }
  },
  computed: {
    ...mapGetters(["notifications"]),
    itemsCount () {
      return this.items.length
    }
  },
  methods: {
    ...mapActions(["setAlerta", "removeNotifications"]),
    onClick(item) {
      if (["file","xlsx","pdf"].includes(item.type)) {
        item.data.click()
      }
    },
    onRemove(item) {
      if (item.type == "file") URL.revokeObjectURL(item.data)
      this.removeNotifications(item)
    },
    removeAll () {
      this.items.forEach(item => this.onRemove(item))
    },
    dateFormat (date) {
      return moment(date).format("DD/MM/yyyy HH:mm:ss");
    },
    showNotification(value = [], state = []) {
      let notification = value.find(notification => !state.includes(notification))
      if (notification) {
        this.message = `${notification.message}`
        this.snackbar = true
      }
    },
    getIcon(type) {
      let icon
      switch (type) {
        case "file":
          icon = "mdi-file-outline"
          break;
        case "pdf":
          icon = "mdi-file-document-outline"
          break;
        case "xlsx":
          icon = "mdi-file-excel-outline"
          break;
        case "error":
          icon = "mdi-alert-circle-outline"
          break;
        default:
          icon = ""
          break;
      }
      return icon
    }
  },
  mounted() {
    this.items = [...this.notifications]
  }
}
</script>

<style>

</style>