<template>
  <v-container fluid class="py-0 px-0">
    <div class="text-center">
      <v-menu
        v-model="menu"
        :open-on-click="false"
        :close-on-content-click="false"
        offset-y
        nudge-top="-5"
        nudge-left="60"
        min-width="200"
        max-height="97%"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            v-on="on"
            solo
            flat
            dense
            filled
            :hide-details="true"
            v-model.trim="modelFilters.pacienteQuery"
            @keypress="setTextSearch"
            @click:append="menu = true"
            append-icon="mdi-filter-variant"
            :label="'ID do Exame ou Paciente: Nome, CPF'"
          >
            <template v-slot:prepend-inner>
              <div v-for="(value, property, index) in modelFilters" :key="index">
                <v-chip-group v-if="isObjectFilter(property, value)">
                  <v-chip v-if="!Array.isArray(value)"
                    class="ml-1"
                    close
                    small
                    @click:close="closeChip(property)"
                  >
                    {{ 
                      property === 'dataInicial' ? 'De: ' + value :
                      property === 'dataFinal' ? 'Até: ' + value :
                      property === 'imagens' ? 'Somente Imagens' :
                      property === 'direcionados' ? 'Somente Direcionados' :
                      property === 'reconvocados' ? 'Somente Reconvocados' :
                      property === 'pendentes' ? 'Somente Pendentes' :
                      property === 'revisoes' ? 'Somente Revisões' :
                      value
                    }}
                  </v-chip>
                  <v-chip v-else v-for="(item, i) of value" :key="i"
                    class="ml-1"
                    close
                    small
                    @click:close="closeArrayChip(value, i)"
                  >
                    {{ 
                      property === 'modalidade' ? item.sigla : 
                      item.nome ? item.nome : 
                      item.descricao ? item.descricao :
                      item.nomeExibicao}}
                  </v-chip>
                </v-chip-group>
              </div>
            </template>
          </v-text-field>
        </template>
        <v-card flat class="pa-5">
          <favorite-filter-select
            :type="'MZW2_EXAME_LIST'"
            :filterSelectOff="filterSelectOff"
            @return-value="favoriteFilterSelect"
          ></favorite-filter-select>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <PeriodoFilter v-model="modelFilters" />
              </v-col>
              <v-col md="4" cols="12">
                <TextFieldFilter
                  v-model="modelFilters.pacienteQuery"
                  tipo="pacienteQuery"
                  :label="'ID do Exame ou Paciente: Nome, CPF'"
                />
              </v-col>
              <v-col md="4" cols="12">
                <TextFieldFilter
                  v-model="modelFilters.pacienteNome"
                  tipo="pacienteNome"
                  :label="'Paciente: Nome ou PatientId'"
                />
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter
                  v-model="modelFilters.grupoEmpresa"
                  tipo="grupoEmpresa"
                />
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter
                  v-model="modelFilters.empresa"
                  tipo="empresa"
                />
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter
                  v-model="modelFilters.unidade"
                  tipo="unidade"
                />
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter v-model="modelFilters.setor" tipo="setor" />
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter
                  v-model="modelFilters.modalidade"
                  tipo="modalidade"
                />
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter
                  v-model="modelFilters.grupoEspecialidade"
                  tipo="grupoEspecialidade"
                />
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter
                  v-model="modelFilters.especialidade"
                  tipo="especialidade"
                />
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter
                  v-model="modelFilters.tipoExame"
                  tipo="tipoExame"
                />
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter
                  v-model="modelFilters.medicoLaudo"
                  tipo="medicoLaudo"
                />
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter
                  v-model="modelFilters.medicoDirecionamento"
                  tipo="medicoDirecionamento"
                />
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter
                  v-model="modelFilters.statusExame"
                  tipo="statusExame"
                />
              </v-col>
              <v-col md="4" cols="12">
                <AutocompleteFilter
                  v-model="modelFilters.solicitacaoPrioridade"
                  tipo="solicitacaoPrioridade"
                />
              </v-col>
              <v-col md="4" cols="12">
                <v-switch
                  v-model="modelFilters.imagens"
                  :label="`Somente com imagens`"
                ></v-switch>
              </v-col>
              <v-col md="4" cols="12">
                <v-switch
                  v-model="modelFilters.direcionados"
                  :label="`Somente Direcionados`"
                ></v-switch>
              </v-col>
              <v-col md="4" cols="12">
                <v-switch
                  v-model="modelFilters.reconvocados"
                  :label="`Somente Reconvocados`"
                ></v-switch>
              </v-col>
              <v-col md="4" cols="12">
                <v-switch
                  v-model="modelFilters.pendentes"
                  :label="`Somente Pendentes`"
                ></v-switch>
              </v-col>
              <v-col md="4" cols="12">
                <v-switch
                  v-model="modelFilters.revisoes"
                  :label="`Somente Revisões`"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end flex-wrap">
            <v-btn color="primary" class="mt-1" :disabled="!isThereAnyFilter" @click="openFavoriteFilter">
              {{ favoriteFilterSelected && favoriteFilterSelected.id ? 'Atualizar Filtro' : 'Criar Filtro' }}
            </v-btn>
            <v-btn color="primary" width="100" class="mt-1" @click="cleanFilters"> Limpar </v-btn>
            <v-btn color="primary" width="100" class="mt-1" @click="closeFilters"> Cancelar </v-btn>
            <v-btn color="primary" width="100" class="mt-1" @click="applyFilters"> Buscar </v-btn>
          </v-card-actions>
          <favorite-filter
            v-model="createFavoriteFilterDialog"
            :favoriteFilterSelected="favoriteFilterSelected"
            :modelFilters="getModelFilters(modelFilters)"
          ></favorite-filter>
        </v-card>
      </v-menu>
    </div>
  </v-container>
</template>

<script>
import AutocompleteFilter from "@/views/exame/AutocompleteFilter.vue";
import TextFieldFilter from "@/views/exame/TextFieldFilter.vue";
import PeriodoFilter from "@/views/exame/PeriodoFilter.vue";
import { mapActions, mapGetters } from "vuex";
import FavoriteFilter from '../../components/FavoriteFilter.vue';
import FavoriteFilterSelect from '../../components/FavoriteFilterSelect.vue';
import { getExameFilters, isObjectFilter } from '@/utils/exameFilter'

export default {
  name: "exame-filter",

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      menu: false,
      modelFilters: {
        textFilter: "",
        urlFilter: "",
        periodo: null,
        dataInicial: null,
        dataFinal: null,
        pacienteNome: null,
        grupoEmpresa: null,
        empresa: null,
        unidade: null,
        setor: null,
        modalidade: null,
        especialidade: null,
        tipoExame: null,
        medicoRadiologista: null,
        medicoLaudo: null,
        medicoDirecionamento: null,
        statusExame: null,
        solicitacaoPrioridade: null,
        imagens: false,
        direcionados: false,
        reconvocados: false,
        pendentes: false,
        revisoes: false,
      },
      createFavoriteFilterDialog: false,
      filterSelectOff: true,
      favoriteFilterSelected: {},
    };
  },

  computed: {
    ...mapGetters(["exameFilters"]),
    defaultFilters() {
      return {
        periodo: null,
        // dataInicial: moment().subtract(10, "days").format("DD/MM/YYYY"),
        // dataFinal: moment().format("DD/MM/YYYY"),
      };
    },
    isThereAnyFilter() {
      let filters = this.modelFilters.dataInicial ? this.modelFilters.dataInicial : null
        || this.modelFilters.dataFinal ? this.modelFilters.dataFinal : null
        || this.modelFilters.pacienteQuery ? this.modelFilters.pacienteQuery : null
        || this.modelFilters.pacienteNome ? this.modelFilters.pacienteNome : null
        || this.modelFilters.grupoEmpresa && this.modelFilters.grupoEmpresa.length > 0 ? this.modelFilters.grupoEmpresa : undefined
        || this.modelFilters.empresa && this.modelFilters.empresa.length > 0 ? this.modelFilters.empresa : undefined
        || this.modelFilters.unidade && this.modelFilters.unidade.length > 0 ? this.modelFilters.unidade : undefined
        || this.modelFilters.setor && this.modelFilters.setor.length > 0 ? this.modelFilters.setor : undefined
        || this.modelFilters.modalidade && this.modelFilters.modalidade.length > 0 ? this.modelFilters.modalidade : undefined
        || this.modelFilters.especialidade && this.modelFilters.especialidade.length > 0 ? this.modelFilters.especialidade : undefined
        || this.modelFilters.tipoExame && this.modelFilters.tipoExame.length > 0 ? this.modelFilters.tipoExame : undefined
        || this.modelFilters.medicoRadiologista && this.modelFilters.medicoRadiologista.length > 0 ? this.modelFilters.medicoRadiologista : undefined
        || this.modelFilters.medicoLaudo && this.modelFilters.medicoLaudo.length > 0 ? this.modelFilters.medicoLaudo : undefined
        || this.modelFilters.medicoDirecionamento && this.modelFilters.medicoDirecionamento.length > 0 ? this.modelFilters.medicoDirecionamento : undefined
        || this.modelFilters.statusExame && this.modelFilters.statusExame.length > 0 ? this.modelFilters.statusExame : undefined
        || this.modelFilters.solicitacaoPrioridade && this.modelFilters.solicitacaoPrioridade.length > 0 ? this.modelFilters.solicitacaoPrioridade : undefined
        || this.modelFilters.imagens ? this.modelFilters.imagens : undefined
        || this.modelFilters.direcionados ? this.modelFilters.direcionados : undefined
        || this.modelFilters.reconvocados ? this.modelFilters.reconvocados : undefined
        || this.modelFilters.pendentes ? this.modelFilters.pendentes : undefined
        || this.modelFilters.revisoes ? this.modelFilters.revisoes : undefined
      return filters ? true : false
    }
  },

  components: {
    AutocompleteFilter,
    TextFieldFilter,
    PeriodoFilter,
    FavoriteFilter,
    FavoriteFilterSelect,
  },

  watch: {
    value(value) {
      this.$emit("input", value);
    },
    exameFilters(value) {
      this.modelFilters = JSON.parse(JSON.stringify(value));
    }
  },

  methods: {
    ...mapActions(["setExameFilters"]),
    getModelFilters(value) {
      return getExameFilters(value)
    },
    async applyFilters() {
      const exameFilters = await getExameFilters(this.modelFilters);
      this.modelFilters = JSON.parse(JSON.stringify(exameFilters));
      this.setExameFilters(exameFilters);
      this.menu = false;
    },
    async cleanFilters() {
      let exameFilters = JSON.parse(JSON.stringify(this.exameFilters));

      for (const property in exameFilters) {
        if (property === "textFilter" || property === "urlFilter") {
          exameFilters[property] = "";
        } else if (property === "showFilters") {
          exameFilters[property] = true;
        } else {
          exameFilters[property] = null;
        }
      }
      exameFilters = { ...exameFilters, ...this.defaultFilters };
      this.modelFilters = await getExameFilters(exameFilters);
    },
    async closeFilters() {
      this.modelFilters = await getExameFilters(this.exameFilters);
      this.menu = false;
    },
    async getFilters() {
      if (!this.exameFilters.dataInicial && !this.exameFilters.dataFinal) {
        let exameFilters = JSON.parse(JSON.stringify(this.exameFilters));

        exameFilters = { ...exameFilters, ...this.defaultFilters };
        this.modelFilters = await getExameFilters(exameFilters);
        this.setExameFilters(exameFilters);
      } else {
        this.modelFilters = await getExameFilters(this.exameFilters);
      }

      this.applyFilters();
    },
    isObjectFilter(property, value) {
      return isObjectFilter(property, value)
    },
    closeChip(property) {
      if (property == 'imagens') {
        this.modelFilters[property] = false;
      } else if (property == 'direcionados') {
        this.modelFilters[property] = false;
      } else if (property == 'reconvocados') {
        this.modelFilters[property] = false;
      } else if (property == 'pendentes') {
        this.modelFilters[property] = false;
      } else if (property == 'revisoes') {
        this.modelFilters[property] = false;
      } else {
        this.modelFilters[property] = null;
      }
      this.applyFilters()
    },
    closeArrayChip(value, index) {
      value.splice(index, 1)
      this.applyFilters()
    },
    setTextSearch(value) {
      if (value.key && value.key === "Enter") {
        this.applyFilters();
      }
    },
    openFavoriteFilter() {
      this.createFavoriteFilterDialog = !this.createFavoriteFilterDialog
    },
    favoriteFilterSelect(value) {
      if (value && value.valor) {
        // If filterSelectOff is true, this method receives the favorite object and changes the modelFilters, but does not apply the filters
        if (this.filterSelectOff) {
          this.modelFilters = JSON.parse(value.valor)
        }
      }
      this.favoriteFilterSelected = { ...value }
    }
  },

  created() {
    this.getFilters();
  },
};
</script>