<template>
  <v-app>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    <component v-bind:is="layout"></component>
  </v-app>
</template>

<script>

import AppLayout from '@/views/layouts/AppLayout'
import LoginLayout from '@/views/layouts/LoginLayout'
// import { getUsuario } from "./securanca";

export default {
  name: 'App',
  computed: {
    layout () {
      return this.$store.getters.layout
    }
  },
  components: {
    AppLayout,
    LoginLayout
  },
  created () {
    // let user =  getUsuario()
    this.$store.dispatch('setAppLayout');
  },

  data: () => ({}),
  methods: {},
}
</script>

<style>
  a {
    text-decoration: none;
  }
  /* 
  #app {
    background-image: url("https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg");
    background-repeat: no-repeat !important;
    background-size: cover !important;
  } */
</style>
