import Vue from 'vue'
//import socketio from "socket.io-client";
//import VueSocketIO from "vue-socket.io";
const jwt = require('jsonwebtoken');

var isLogged = function (to, from, next) {

  let token = window.localStorage.getItem('tokenMZ2')
  
  if (token) {    
    if (validateToken(token)) {
      // Implementa a validação de permissões para as telas.
      const { roles } = to.meta
      if (roles.length > 0 && !possuiPermissao(roles)) {
        next(from.path || "/");
      } else {
        next()
      }
    }else{
      loginPage(to, next)
    }
  } else {
    loginPage(to, next)
  }
}


function auth(to, next) {
  // let id = to.params.perfilId || to.params.id;
  // console.log('id ',  id);
  
  Vue.prototype.$auth
    .post("/login")
    .then(async response => {
      response
      next()
    })
    .catch(erro => {
      console.log('erro: ', erro);
      loginPage(to, next)
    });
}

function loginPage(to, next) {  
  window.localStorage.removeItem('tokenMZ2')
  next({
    path: '/login',
     query: {
       redirect: to.path //fullPath
     }
  })
}

function logOut(){
  window.localStorage.clear();
  window.sessionStorage.clear();
  //removeItem('tokenMZ2');
}

function validateToken(token) {
    var decoded = jwt.decode(token);
    //console.log('->>', decoded.exp, decoded);
    return (decoded && Date.now() < decoded.exp * 1000)

}
/*
function isTokenValid() {
  let token = window.localStorage.getItem('tokenMZ2')
  return (token && validateToken(token))
}
*/
function getPermissao() {
  let token = window.localStorage.getItem('tokenMZ2')  

  var decoded = jwt.decode(token);
  //console.log('->>', decoded.exp, decoded);
  return decoded ? decoded.roles : []
}

function getUsuario() {
  let token = window.localStorage.getItem('tokenMZ2')
  var decoded = jwt.decode(token);
  return decoded ? decoded.sub : "";
}

function getPerfilId() {
  let token = window.localStorage.getItem('tokenMZ2')
  var decoded = jwt.decode(token);
  return decoded ? decoded.perfilId : "";
}

function getNomeExibicao() {
  let token = window.localStorage.getItem('tokenMZ2')
  var decoded = jwt.decode(token);
  return decoded ? decoded.nomeExibicao : "";
}

function getUsuarioId() {
  let token = window.localStorage.getItem('tokenMZ2')
  var decoded = jwt.decode(token);
  return decoded ? decoded.id : null;
}

function clearTemporaryUserPreferences(componentName){
  let up = window.sessionStorage.getItem(Vue.prototype.$UserPreferencesinstanceName)
  if (up){
    var obj = JSON.parse(window.atob(up));
    if (obj[getUsuario()]){
        for(var p_componentName in obj[getUsuario()]){
          if (componentName == p_componentName){
            for(var operation in obj[getUsuario()][p_componentName]){
              if (operation.indexOf("temporary") > -1){
                storeUserPreferences(p_componentName, operation, "", obj[getUsuario()][p_componentName]['options']);
                break;
              }
            }
          }
        }
    }
  }
}

function makeIfNotExistsUserPreferences(componentName){
  let up = window.sessionStorage.getItem(Vue.prototype.$UserPreferencesinstanceName)
  if (!up){
    window.sessionStorage.setItem(Vue.prototype.$UserPreferencesinstanceName,window.btoa(
    `{ 
      "${getUsuario()}": {
        "${componentName}": { 
          "last-rest-call": "",
          "last-list-click": "",
          "last-list-click-temporary": "", 
          "options": ""
        }
      }
    }`));

  }else{
    
    var obj = JSON.parse(window.atob(up));
    
    if (!obj[getUsuario()]){
      obj[getUsuario()] = {};
    }


    if (!obj[getUsuario()][componentName]){
      
      obj[getUsuario()][componentName] = {
        "last-rest-call": "",
        "last-list-click": "",
        "last-list-click-temporary": "", 
        "options": ""
      };
      
      window.sessionStorage.setItem(Vue.prototype.$UserPreferencesinstanceName,window.btoa(JSON.stringify(obj)));
    }
  }

}

function storeUserPreferences(componentName, propriedade, valor, options) {
    makeIfNotExistsUserPreferences(componentName);
    var obj = JSON.parse(window.atob(window.sessionStorage.getItem(Vue.prototype.$UserPreferencesinstanceName)));
    obj[getUsuario()][componentName][propriedade] = valor;
    if (options)
      obj[getUsuario()][componentName]['options'] = options;
    window.sessionStorage.setItem(Vue.prototype.$UserPreferencesinstanceName,window.btoa(JSON.stringify(obj)))
}

function getUserPreferences(componentName) {
  makeIfNotExistsUserPreferences(componentName);
  var obj = JSON.parse(window.atob(window.sessionStorage.getItem(Vue.prototype.$UserPreferencesinstanceName)));
  
  return obj[getUsuario()][componentName];
}

function isAdminMaster(){
  return possuiPermissao("ROLE_ADMIN");
}

function isAdminAuxiliar(){
  return possuiPermissao("ROLE_AUXILIAR_ADMIN");
}

function isAdminClient(){
  return possuiPermissao("ROLE_CLIENTE_ADMIN");
}

function possuiPermissao(params) {
  let has = false
  let permissions = getPermissao()
  if (permissions) {
    if (Array.isArray(params)) {
      params.forEach(element => {
        if (permissions.includes(element)) {
          has = true
        }
      });
    }else{
      has = permissions.includes(params)  
    }  
  }
  return has
}

export {
  isLogged,
  getUsuario,
  possuiPermissao,
  getPermissao,
  logOut,
  auth,
  storeUserPreferences,
  getUserPreferences,
  getPerfilId,
  getNomeExibicao,
  getUsuarioId,
  clearTemporaryUserPreferences,
  isAdminMaster,
  isAdminAuxiliar,
  isAdminClient
}
