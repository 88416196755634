export default {
  setAppLayout(context) {
    context.commit('setLayout', {
      query: 'app-layout'
    })
  },
  setLoginLayout(context) {
    context.commit('setLayout', {
      query: 'login-layout'
    })
  },
  setUltimaTela(context, param) {
    context.commit('setUltimaTela', param)
  },
  setAlerta(context, param) {
    context.commit('setAlerta', param)
  },

  setAgendamentoQuery(context, param) {
    context.commit('setAgendamentoQuery', param)
  },
  setAgendamentoRefreshList(context, param) {
    context.commit('setAgendamentoRefreshList', param)
  },
  setAgendamentoLastItemId(context, param) {
    context.commit('setAgendamentoLastItemId', param)
  },

  setAtendimentoQuery(context, param) {
    context.commit('setAtendimentoQuery', param)
  },
  setAtendimentoRefreshList(context, param) {
    context.commit('setAtendimentoRefreshList', param)
  },
  setAtendimentoLastItemId(context, param) {
    context.commit('setAtendimentoLastItemId', param)
  },
  setRelatorioAtendimentoExportQuery(context, param) {
    context.commit('setRelatorioAtendimentoExportQuery', param)
  },

  setExameQuery(context, param) {
    context.commit('setExameQuery', param)
  },
  setExameRefreshList(context, param) {
    context.commit('setExameRefreshList', param)
  },
  setExameLastItemId(context, param) {
    context.commit('setExameLastItemId', param)
  },
  setExameFilters(context, param) {
    context.commit('setExameFilters', param)
  },
  setShowExameFilters(context, param) {
    context.commit('setShowExameFilters', param)
  },

  setReportsQuery(context, param) {
    context.commit('setReportsQuery', param)
  },
  setReportsFilters(context, param) {
    context.commit('setReportsFilters', param)
  },
  setShowReportsFilters(context, param) {
    context.commit('setShowReportsFilters', param)
  },

  setUpdateFavoriteModel(context, param) {
    context.commit('setUpdateFavoriteModel', param)
  },

  setNotifications(context, param) {
    context.commit('setNotifications', param)
  },

  addNotifications(context, param) {
    context.commit('addNotifications', param)
  },

  removeNotifications(context, param) {
    context.commit('removeNotifications', param)
  },

  setAutoTextoQuery(context, param) {
    context.commit('setAutoTextoQuery', param)
  },
}
