<template>
  <v-container class="pa-0">
    <v-list dense shaped id="vListAlterarSenhaComponent">
      <v-list-group append-icon="">
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-avatar class="mr-2" color="primary">
              <!-- <v-img src="https://randomuser.me/api/portraits/men/99.jpg"></v-img> -->
              <span class="white--text"> {{ nomeUsuarioSigla }} </span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title> {{ nomeUsuario }}</v-list-item-title>

              <v-list-item-subtitle v-if="permissoesUsuario != null && permissoesUsuario.length > 0">
                <v-tooltip open-delay="800" bottom attach>
                  <template v-slot:activator="tooltip">
                      <span v-on="{...tooltip.on}"> {{ permissoesUsuario[0].descricao }} </span>
                  </template>
                  <tr v-for="permissao in permissoesUsuario" :key="permissao.id">
                    <td>
                      <span> {{ permissao.descricao }} </span>
                    </td>
                  </tr>
                </v-tooltip>
              </v-list-item-subtitle>

            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item @click="dialog = !dialog" :class="miniVariant ? 'pl-4' : 'pl-10'">
          <v-list-item-icon>
            <v-icon>mdi-account-key</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Alterar Senha</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    <v-divider></v-divider>

    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-form
          lazy-validation
          v-on:submit.prevent="enviarOsDadosParaAlterarSenha"
          enctype="multipart/form-senha"
          ref="form"
        >
          <v-card-title>
            <span class="headline primary--text">Alterar senha do Usuário</span>
          </v-card-title>
          <v-container>
            <v-card-text>
              <span></span>
              <v-text-field
                :rules="rules.textoObrigatorio"
                v-model="senhaAtual"
                type="password"
                filled
                rounded
                required
                label="Senha atual"
              >
              </v-text-field>

              <span></span>
              <v-text-field
                :rules="rules.textoObrigatorio"
                v-model="novaSenha"
                type="password"
                filled
                rounded
                required
                label="Nova senha"
              >
              </v-text-field>

              <span></span>
              <v-text-field
                :rules="rules.textoObrigatorio"
                v-model="confirmarSenha"
                type="password"
                filled
                rounded
                reiquired
                label="Confirme nova senha"
              >
              </v-text-field>
            </v-card-text>
          </v-container>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="2" @click="cancelar">Cancelar</v-btn>
            <v-btn type="submit" :disabled="!todosCamposValidos" color="primary">Gravar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import { mapActions } from 'vuex'
import { getPermissao } from "../../seguranca";

export default {
  name: "AlterarSenha",
  props: {
    nomeUsuario: {
      type: String,
      default: null,
      require: true,
    },
    perfilId: {
      type: String,
      default: null,
      require: true,
    },
    miniVariant: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      senhaAtual: null,
      novaSenha: null,
      confirmarSenha: null,
      permissoes: [],
      permissoesUsuario: [],

      changePassword: {
        perfilId: null,
        atualPassword: null,
        newPassword: null,
        confirmPassword: null,
      },

      rules: {
        campoObrigatorio: [(v) => !!v || "Campo é obrigatório"],
        textoObrigatorio: [
          (v) => !!v || "Campo é obrigatório",
          (v) => (v && !!v.trim()) || "Valor não pode ser branco",
        ],
        emailObrigatorio: [
          (v) => !!v || "E-mail obrigatório",
          (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
        ],
        selecaoObrigatoria: [(v) => v.length > 0 || "Seleção Obrigatória"],
      },
    };
  },
  methods: {
    ...mapActions([
      'setAlerta',
    ]),
    enviarOsDadosParaAlterarSenha() {
      var self = this;
      if (!self.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Favor preencher todos os campos",
        });
      } else {
        if (this.novaSenha != null && this.confirmarSenha != null && this.novaSenha === this.confirmarSenha) {
          
          // Informa os dados para o objeto changePassword
          self.changePassword.perfilId = this.perfilId;
          self.changePassword.atualPassword = this.senhaAtual;
          self.changePassword.newPassword = this.novaSenha;
          self.changePassword.confirmPassword = this.confirmarSenha;
          
          // Realiza a chamada para alteração de senha
          this.$http
            .put("/usuario/changePassword", self.changePassword)
            .then(response => {
              if(response.data.code == "0") {
                self.setAlerta({
                  tipoAlerta: "success",
                  textAlerta: response.data.msg,
                }); 
                this.cancelar(); 
              } else {
                self.setAlerta({
                  tipoAlerta: "error",
                  textAlerta: response.data.msg,
                });
              }
            })
            .catch(() => {
              self.setAlerta({
                tipoAlerta: "error",
                textAlerta: "Erro ao executar a troca de senha"
              });
            });
        } else {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro as senhas não conferem!",
          });
        }
      }
    },
    cancelar() {
      this.dialog = false;
    },
    buscarPermissoes() {
      var self = this;
      this.$http
        .get(`/permissao?max=500`)
        .then((response) => {
          this.permissoes = response.data.content;

          if(Array.isArray(this.permissoes)) {
            this.permissoesUsuario = this.permissoes
              .filter(p => getPermissao().filter(pu => pu == p.authority).length > 0)
              .sort((p1, p2) => p1.id - p2.id)
              .map(p => ({...p}))
          }
        })
        .catch((error) => {
          if (error.response.status !== 401) {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao buscar permissão",
            });
          }
        });
    },
  },
  computed: {
    todosCamposValidos() {
      if (this.senhaAtual != null && this.novaSenha != null && this.confirmarSenha != null) {
        if (this.novaSenha === this.confirmarSenha) {
          return true;
        }
      }
      return false;
    },
    nomeUsuarioSigla () {
      return this.nomeUsuario.slice(0, 2).toUpperCase();
    },
  },
  created () {
    this.buscarPermissoes()
  }
};
</script>

<style scoped>
  ::v-deep #vListAlterarSenhaComponent .v-list-item {
    padding: 0px 4px 0px 4px;
  }
</style>>