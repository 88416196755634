<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-form lazy-validation v-on:submit.prevent="saveFavoriteFilter" ref="form">
      <v-card flat>
        <v-card-title>
          <span class="headline primary--text">Editar/Adicionar Filtros</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="favoriteModel.nome"
            label="Nome do filtro"
            hide-details="false"
            rounded
            filled
            dense
            :rules="[rules.campoObrigatorio, rules.textoObrigatorio]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text class="red--text darken-1" @click="closeFavoritFilter">Cancelar</v-btn>
          <v-btn v-if="favoriteModel.id" text class="primary--text darken-1" @click="updateFavoriteFilter">Atualizar</v-btn>
          <v-btn text type="submit" class="green--text darken-1">Adicionar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "favorite-filter",

  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    modelFilters: {
      type: Object,
      default: null,
      required: true,
    },
    favoriteFilterSelected: {
      type: Object,
      default: null,
      required: true,
    }
  },
  data() {
    return {
      dialog: false,
      favoriteModel: {},
      rules: {
        campoObrigatorio: (v) => !!v || "Campo é obrigatório",
        textoObrigatorio: (v) =>
          (v && !!v.trim()) || "Valor não pode ser branco",
      },
    };
  },
  watch: {
    value(value) {
      this.dialog = value;
    },
    dialog(value) {
      this.$emit("input", value);
    },
    favoriteFilterSelected(value) {
      this.favoriteModel = { ...value }
    }
  },
  methods: {
    ...mapActions(["setAlerta","setUpdateFavoriteModel"]),
    updateFavoriteFilter() {
      const self = this

      // Validação de campos obrigatórios preenchidos
      self.validateForm()

      // Tratamento para favorito com período
      self.changeExistsPeriodo(self.modelFilters)

      self.favoriteModel = {
        ...self.favoriteModel,
        valor: JSON.stringify(Object.assign({}, self.modelFilters)),
      }

      self.$http
        .put("/favorito", self.favoriteModel)
        .then((response) => {
          self.setAlerta({
            tipoAlerta: "success",
            textAlerta: "Filtro atualizado com sucesso!",
          });
          self.$emit("return-value", response.data);
          self.setUpdateFavoriteModel(true)
          this.closeFavoritFilter();
        })
        .catch((error) => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: error.message ? "Nome de filtro já utilizado!" :
              "Ocorreu um erro ao criar o filtro. Por favor, contate o Administrador!",
          });
        });
    },
    saveFavoriteFilter() {
      const self = this;

      // Validação de campos obrigatórios preenchidos
      self.validateForm()

      // Tratamento para favorito com período
      self.changeExistsPeriodo(self.modelFilters)

      self.favoriteModel = {
        ...self.favoriteModel,
        tipo: "MZW2_EXAME_LIST",
        valor: JSON.stringify(Object.assign({}, self.modelFilters)),
      };

      self.$http
        .post("/favorito", self.favoriteModel)
        .then((response) => {
          self.setAlerta({
            tipoAlerta: "success",
            textAlerta: "Filtro criado com sucesso!",
          });
          self.$emit("return-value", response.data);
          self.setUpdateFavoriteModel(true)
          this.closeFavoritFilter();
        })
        .catch((error) => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: error.message ? "Nome de filtro já utilizado!" :
              "Ocorreu um erro ao criar o filtro. Por favor, contate o Administrador!",
          });
        });
    },
    closeFavoritFilter() {
      this.dialog = !this.dialog;
    },
    validateForm() {
      // Validação de campos obrigatórios preenchidos
      const self = this
      if (!self.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Favor preencher todos os campos obrigatórios",
        });
        return;
      }
    },
    changeExistsPeriodo(modelFilters) {
      if (modelFilters.periodo) {
        modelFilters.dataInicial = null
        modelFilters.dataFinal = null
      }
    }
  },
};
</script>