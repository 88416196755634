<template>
  <v-container fluid class="pa-0 ma-0">
    <v-text-field
      dense
      filled
      rounded
      clearable
      :label="label"
      v-model="textField"
      :hide-details="true"
    ></v-text-field>
  </v-container>
</template>

<script>
export default {
  name: "text-field-filter",

  props: {
    value: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "Paciente",
    }
  },

  data() {
    return {
      textField: "",
    };
  },

  watch: {
    value(value) {
      this.textField = value;
    },
    textField(value) {
      this.$emit("input", value);
    },
  },

  created() {
    this.textField = this.value;
  },
};
</script>